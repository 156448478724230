import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { onValue, ref, getDatabase, update, onChildChanged } from 'firebase/database';
import { database } from '../firebaseConfig';
import {
  createPresentation,
  updatePresentation,
  addInteractionToPresentation,
  createInteraction,
  getPresentationById,
  updateInteractionInPresentation,
  removeInteraction,
  generateInteractionId,
} from '../firebaseFunctions';
import styles from './CreatePresentation.module.css';
import { AuthContext } from '../firebaseAuthFunctions';
import logo from '../images/youpin-logo-mobile-header.svg';
import nuvemDePalavrasImg from '../images/nuvem-de-palavras-interacao.png';
import multimediaImg from '../images/multimidia-interacao.png';
import pollImg from '../images/pesquisa-interacao.png';
import quizImg from '../images/quiz-interacao.png';
import insightsImg from '../images/insights-interaction.png';
import hotPotatoImg from '../images/hot-potato-interaction.png';
import textCompleteImg from '../images/text-complete-interaction.png';
import drawImg from '../images/draw-interaction.png';
import { ReactComponent as BackIcon } from '../images/back-arrow-icon.svg';
import { ReactComponent as DeleteIcon } from '../images/delete-icon.svg';
import { ReactComponent as DuplicateIcon } from '../images/duplicate-icon.svg';
import { ReactComponent as AddIcon } from '../images/add-icon.svg';
import { ReactComponent as PlayIcon } from '../images/play-icon.svg';

// Importar os novos componentes de formulário
import WordcloudForm from '../interactions/wordcloud/WordcloudForm';
import MultimediaForm from '../interactions/multimedia/MultimediaForm';
import PollForm from '../interactions/poll/PollForm';
import InsightsForm from '../interactions/insights/InsightsForm';
import QuizForm from '../interactions/quiz/QuizForm';

// Importar preview
import interactionMapping from '../interactions/interactionMapping';

// Importações do react-beautiful-dnd
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Definição imagem default

const wellcomeImg = 'https://firebasestorage.googleapis.com/v0/b/youpin-e65f5.appspot.com/o/util%2Fyoupin-wellcome-image.png?alt=media&token=983174f6-9271-4773-bcb5-5d54a5fdafc9';

function CreatePresentation() {
  const location = useLocation();
  const { state } = location;
  const { presentation } = state || {};
  const [presentationId, setPresentationId] = useState(presentation?.id || null);
  const [title, setTitle] = useState(presentation?.title || 'Nova apresentação');
  const [description, setDescription] = useState(presentation?.description || 'Sobre o que é sua descrição?');
  const [interactions, setInteractions] = useState(presentation?.interactions ? Object.values(presentation.interactions) : []);
  const [selectedInteraction, setSelectedInteraction] = useState(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [lastSavedTime, setLastSavedTime] = useState(new Date());
  const [savingStatus, setSavingStatus] = useState('Salvo');
  const [isInteractionModified, setIsInteractionModified] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [pin, setPin] = useState(presentation?.pin || null); // Novo estado para armazenar o pin
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showSecondColumn, setShowSecondColumn] = useState(false);
  const [showThirdColumn, setShowThirdColumn] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const setupPresentationUpdateListener = (userId, presentationId) => {
    const db = getDatabase();
    const presentationRef = ref(db, `presentations/${userId}/${presentationId}`);

    onChildChanged(presentationRef, async (snapshot) => {
      const changedKey = snapshot.key;
      if (changedKey !== 'updatedAt') {
        const updatedAt = new Date().toISOString();
        await update(presentationRef, { updatedAt });
      }
    });
  };

  useEffect(() => {
    if (presentationId && currentUser) {
      setupPresentationUpdateListener(currentUser.uid, presentationId);
    }
  }, [presentationId, currentUser]);

  useEffect(() => {
    const createInitialPresentation = async () => {
      if (!presentationId && currentUser) {
        const newPresentationId = await createPresentation(currentUser.uid, title, description, []);
        setPresentationId(newPresentationId);
        const newPresentationData = await getPresentationById(currentUser.uid, newPresentationId);
        setPin(newPresentationData.pin);

        const defaultMultimediaInteraction = createDefaultMultimediaInteraction(newPresentationId);
        await addInteractionToPresentation(currentUser.uid, newPresentationId, defaultMultimediaInteraction);

        const updatedPresentationData = await getPresentationById(currentUser.uid, newPresentationId);
        const sortedInteractions = Object.values(updatedPresentationData.interactions || {}).sort((a, b) => a.order - b.order);
        setInteractions(sortedInteractions);
      }
    };
    createInitialPresentation();
  }, [currentUser, presentationId, title, description]);

  useEffect(() => {
    const fetchPresentationData = async () => {
      if (presentationId && currentUser) {
        const presentationData = await getPresentationById(currentUser.uid, presentationId);
        const sortedInteractions = Object.values(presentationData.interactions || {}).sort((a, b) => a.order - b.order);
        setInteractions(sortedInteractions);
        setLastUpdateTime(new Date(presentationData.updatedAt));
        setPin(presentationData.pin);
      }
    };
    fetchPresentationData();
  }, [presentationId, currentUser]);

  useEffect(() => {
    const dbRef = ref(database, `presentations/${currentUser.uid}/${presentationId}/updatedAt`);
    const unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        setLastUpdateTime(new Date(snapshot.val()));
      }
    });

    return () => unsubscribe();
  }, [presentationId, currentUser]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLastSavedTime(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const timeSinceLastSave = () => {
    if (!lastUpdateTime) return 'Carregando...';
    const now = new Date();
    const diffInMs = now - lastUpdateTime;
    const diffInMinutes = Math.floor(diffInMs / 60000);

    if (diffInMinutes < 1) {
      return 'Salvo há menos de 1 minuto';
    } else if (diffInMinutes < 60) {
      return `Salvo há ${diffInMinutes} minutos`;
    } else if (diffInMinutes < 1440) {
      const hours = Math.floor(diffInMinutes / 60);
      return `Salvo há ${hours} horas e ${diffInMinutes % 60} minutos`;
    } else if (diffInMinutes < 10080) {
      const days = Math.floor(diffInMinutes / 1440);
      return `Salvo há ${days} dias`;
    } else if (diffInMinutes < 40320) {
      const weeks = Math.floor(diffInMinutes / 10080);
      return `Salvo há ${weeks} semanas`;
    } else {
      const months = Math.floor(diffInMinutes / 40320);
      return `Salvo há ${months} ${months === 1 ? 'mês' : 'meses'}`;
    }
  };

  const createDefaultMultimediaInteraction = (presentationId) => {
    return {
      id: generateInteractionId('multimedia'),
      type: 'multimedia',
      title: 'Bem-vindo',
      order: 0,
      presentationId: presentationId,
      content: {
        mediaType: 'image',
      },
      image: wellcomeImg,
    };
  };

  const handleAddInteraction = (type) => {
    const newInteraction = createInteraction(type);
    setSelectedInteraction(newInteraction);
    setIsInteractionModified(true);
    if (isMobile) {
      setShowSecondColumn(true);
      setShowThirdColumn(false);
    }
  };

  const handleSaveInteraction = async () => {
    if (presentationId && selectedInteraction) {
      const existingInteraction = interactions.find(interaction => interaction.id === selectedInteraction.id);

      if (existingInteraction) {
        await updateInteractionInPresentation(currentUser.uid, presentationId, selectedInteraction);
      } else {
        await addInteractionToPresentation(currentUser.uid, presentationId, selectedInteraction);
      }

      const presentationData = await getPresentationById(currentUser.uid, presentationId);
      const sortedInteractions = Object.values(presentationData.interactions || {}).sort((a, b) => a.order - b.order);
      setInteractions(sortedInteractions);
      setSelectedInteraction(null);
      setIsInteractionModified(false);
      if (isMobile) {
        setShowSecondColumn(false);
        setShowThirdColumn(false);
      }
    }
  };

  const handleUpdateInteraction = (field, value) => {
    const updatedInteraction = { ...selectedInteraction, [field]: value };
    setSelectedInteraction(updatedInteraction);
    setIsInteractionModified(true);
  };

  const handleCancelInteraction = () => {
    setSelectedInteraction(null);
    setIsInteractionModified(false);
    if (isMobile) {
      setShowSecondColumn(false);
      setShowThirdColumn(false);
    }
  };

  const handleSavePresentation = async () => {
    if (!currentUser) return;
    setSavingStatus('Salvando...');
    if (presentationId) {
      const updatedAt = new Date().toISOString();
      await updatePresentation(currentUser.uid, presentationId, title, description, updatedAt);
      setLastSavedTime(new Date());
      setSavingStatus('Salvo');
      setIsEditingTitle(false);
      setIsEditingDescription(false);
    }
  };

  const handleKeyDown = (e, saveFunction) => {
    if (e.key === 'Enter') {
      saveFunction();
    }
  };

  const isInteractionInProgress = () => {
    return isInteractionModified || selectedInteraction !== null;
  };

  const handleSelectInteraction = (interaction) => {
    setSelectedInteraction(interaction);
    setIsInteractionModified(true);
    if (isMobile) {
      setShowSecondColumn(true);
      setShowThirdColumn(false);
    }
  };

  const handleViewInteractionOptions = () => {
    setSelectedInteraction(null);
    setIsInteractionModified(false);
    if (isMobile) {
      setShowSecondColumn(false);
      setShowThirdColumn(true);
    }
  };

  const handleDeleteInteraction = async (interactionId, e) => {
    e.stopPropagation();
    if (window.confirm('Tem certeza que deseja deletar essa interação?')) {
      await removeInteraction(currentUser.uid, presentationId, interactionId);
      const updatedPresentation = await getPresentationById(currentUser.uid, presentationId);
      const sortedInteractions = Object.values(updatedPresentation.interactions || {}).sort((a, b) => a.order - b.order);
      setInteractions(sortedInteractions);
    }
  };

  const handleDuplicateInteraction = async (interaction, e) => {
    e.stopPropagation();
    const newInteraction = { ...interaction, id: generateInteractionId(interaction.type), title: interaction.title + ' (cópia)' };

    const originalIndex = interactions.findIndex(i => i.id === interaction.id);

    const newInteractions = [...interactions];
    newInteractions.splice(originalIndex + 1, 0, newInteraction);

    newInteractions.forEach((interaction, index) => {
      interaction.order = index;
    });

    setInteractions(newInteractions);

    await addInteractionToPresentation(currentUser.uid, presentationId, newInteraction);

    const interactionUpdates = newInteractions.reduce((acc, interaction) => {
      acc[interaction.id] = interaction;
      return acc;
    }, {});

    const db = getDatabase();
    await update(ref(db, `presentations/${currentUser.uid}/${presentationId}/interactions`), interactionUpdates);
  };

  const renderInteractionForm = () => {
    if (!selectedInteraction) return null;
    switch (selectedInteraction.type) {
      case 'wordcloud':
        return <WordcloudForm interaction={selectedInteraction} handleUpdateInteraction={handleUpdateInteraction} />;
      case 'multimedia':
        return <MultimediaForm interaction={selectedInteraction} handleUpdateInteraction={handleUpdateInteraction} />;
      case 'poll':
        return <PollForm interaction={selectedInteraction} handleUpdateInteraction={handleUpdateInteraction} />;
      case 'insights':
        return <InsightsForm interaction={selectedInteraction} handleUpdateInteraction={handleUpdateInteraction} />;
      case 'quiz':
        return <QuizForm userId={currentUser.uid} presentationId={presentationId} interaction={selectedInteraction} handleUpdateInteraction={handleUpdateInteraction} onClose={handleCancelInteraction} />;
      default:
        return null;
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedInteractions = Array.from(interactions);
    const [movedInteraction] = reorderedInteractions.splice(result.source.index, 1);
    reorderedInteractions.splice(result.destination.index, 0, movedInteraction);

    reorderedInteractions.forEach((interaction, index) => {
      interaction.order = index;
    });

    setInteractions(reorderedInteractions);

    const interactionUpdates = reorderedInteractions.reduce((acc, interaction) => {
      acc[interaction.id] = interaction;
      return acc;
    }, {});

    const db = getDatabase();
    await update(ref(db, `presentations/${currentUser.uid}/${presentationId}/interactions`), interactionUpdates);
  };

  const handleStartPresentation = () => {
    if (pin) {
      window.open(`/projection/${pin}`, '_blank');
    }
  };

  return (
    <div className={styles.createPresentationContainer}>

      {/* Coluna Esquerda: Lista de Interações */}
      <div className={`${styles.firstColumn} ${isMobile && (showSecondColumn || showThirdColumn) ? styles.hide : ''}`}>
        <div className={styles.interactionListContainer}>

          {/* Conteúdo Superior Fixo */}
          <div className={styles.fixedContent}>
            <div className={styles.headerGroup}>
              <div className={styles.backButton} onClick={() => navigate('/account/home')}>
                <BackIcon />
              </div>
              <img src={logo} alt="YouPin logo" />
            </div>

            {/* Entrada para Título da Apresentação */}
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Título da apresentação</label>
              <div className={`${styles.editable} ${isEditingTitle ? styles.editing : ''}`} onClick={() => setIsEditingTitle(true)}>
                {isEditingTitle ? (
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={handleSavePresentation}
                    onKeyDown={(e) => handleKeyDown(e, handleSavePresentation)}
                    autoFocus
                    className={styles.editInput}
                  />
                ) : (
                  <h2>{title}</h2>
                )}
              </div>
            </div>

            {/* Entrada para Descrição da Apresentação */}
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Descrição da apresentação</label>
              <div className={`${styles.editable} ${isEditingDescription ? styles.editing : ''}`} onClick={() => setIsEditingDescription(true)}>
                {isEditingDescription ? (
                  <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onBlur={handleSavePresentation}
                    onKeyDown={(e) => handleKeyDown(e, handleSavePresentation)}
                    autoFocus
                    className={styles.editInput}
                  />
                ) : (
                  <p>{description}</p>
                )}
              </div>
            </div>

            <div className={styles.divider}></div>

            {/* Botão para Adicionar Nova Interação */}
            <div className={styles.addButton} onClick={handleViewInteractionOptions}>
              <AddIcon />
              Adicionar interação
            </div>

            <div className={styles.divider}></div>
          </div>

          {/* Conteúdo Rolável: Lista de Interações */}
          <div className={styles.scrollableContent}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="interactionList">
                {(provided) => (
                  <div className={styles.interactionList} {...provided.droppableProps} ref={provided.innerRef}>
                    {Array.isArray(interactions) && interactions.map((interaction, index) => (
                      <Draggable key={interaction.id} draggableId={interaction.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${styles.interactionItem} ${selectedInteraction && selectedInteraction.id === interaction.id ? styles.selectedItem : ''}`}
                            onClick={() => handleSelectInteraction(interaction)}
                          >
                            <p>
                              {/* mostra o número da atividade baseado no índice da lista */}
                              {index + 1}
                            </p>
                            <div>
                              <div className={styles.interactionTitle}>
                                <div className={styles.interactionDescription}>{interaction.title || 'Título da Interação'}</div>
                              </div>
                              {
                                interaction.type === 'wordcloud' ? 'Nuvem de Palavras' :
                                  interaction.type === 'multimedia' ? 'Multimídia' :
                                    interaction.type === 'poll' ? 'Enquete' :
                                      interaction.type === 'insights' ? 'Insights' :
                                        interaction.type === 'quiz' ? 'Quiz' :
                                          'Tipo Desconhecido'
                              }
                            </div>
                            <div className={styles.interactionButtons}>
                              <button className={styles.duplicateButton} onClick={(e) => handleDuplicateInteraction(interaction, e)}>
                                <DuplicateIcon />
                              </button>
                              <button className={styles.deleteButton} onClick={(e) => handleDeleteInteraction(interaction.id, e)}>
                                <DeleteIcon />
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>

      {/* Coluna do Meio: Formulário de Edição de Interação */}
      {selectedInteraction && (
        <div className={`${styles.secondColumn} ${isMobile && showSecondColumn ? styles.show : ''}`}>
          <div className={styles.secondColumnHeader}>
            <div className={styles.backButton} onClick={handleCancelInteraction}>
              <BackIcon />
            </div>
            <h3>Configure
              <span className={styles.selectedInteractionTitle}>
                {
                  selectedInteraction.type === 'wordcloud' ? ' sua Nuvem de Palavras' :
                    selectedInteraction.type === 'multimedia' ? ' sua Multimídia' :
                      selectedInteraction.type === 'poll' ? ' sua Enquete' :
                        selectedInteraction.type === 'insights' ? ' seu insights' :
                          selectedInteraction.type === 'quiz' ? ' seu Quiz' :
                            'sua interação'
                }
              </span>:
            </h3>
          </div>
          <div className={styles.divider}></div>
          <div className={styles.secondColumnContent}>
            {renderInteractionForm()}
          </div>
          <div className={styles.saveCancelArea}>
            <div className={styles.divider}></div>
            <button onClick={handleSaveInteraction} className={styles.saveInteractionButton} disabled={!isInteractionModified}>Salvar</button>
            <button onClick={handleCancelInteraction} className={styles.cancelButton}>Cancelar</button>
          </div>
        </div>
      )}

      {(!selectedInteraction || !isMobile) && (
        <div className={`${styles.thirdColumn} ${isMobile && showThirdColumn ? styles.show : ''}`}>
          <div className={styles.headerGroup}>
            <h3>Adicione uma interação</h3>
            <div className={styles.rightSide}>
              <p>{savingStatus === 'Salvando...' ? 'Salvando...' : timeSinceLastSave()}</p>
              <button className={styles.addButton} onClick={handleStartPresentation}>
                <PlayIcon />
                Apresentar
              </button>
            </div>
          </div>

          <div className={styles.thirdColumnContent}>
            {!selectedInteraction ? (
              <div className={styles.previewSection}>
                <div className={styles.previewText}>Escolha uma interação para incluir na sua apresentação</div>
                <input
                  type="text"
                  placeholder="Pesquisar..."
                  className={styles.searchInput}
                />
                <div className={styles.interactionCards}>
                  <div>
                    <div className={styles.card} onClick={() => handleAddInteraction('wordcloud')}>
                      <img src={nuvemDePalavrasImg} alt="Nuvem de Palavras" className={styles.cardImage} />
                    </div>
                    <p className={styles.cardTitle}>Nuvem de Palavras</p>
                  </div>
                  <div>
                    <div className={styles.card} onClick={() => handleAddInteraction('multimedia')}>
                      <img src={multimediaImg} alt="Multimídia" className={styles.cardImage} />
                    </div>
                    <p className={styles.cardTitle}>Multimídia</p>
                  </div>
                  <div>
                    <div className={styles.card} onClick={() => handleAddInteraction('poll')}>
                      <img src={pollImg} alt="Enquete" className={styles.cardImage} />
                    </div>
                    <p className={styles.cardTitle}>Enquete</p>
                  </div>
                  <div>
                    <div className={styles.card} onClick={() => handleAddInteraction('insights')}>
                      <img src={insightsImg} alt="Insights" className={styles.cardImage} />
                    </div>
                    <p className={styles.cardTitle}>Insights</p>
                  </div>
                  <div>
                    <div className={styles.card} onClick={() => handleAddInteraction('quiz')}>
                      <img src={quizImg} alt="Quiz" className={styles.cardImage} />
                    </div>
                    <p className={styles.cardTitle}>Quiz</p>
                  </div>
                  <div>
                    <div className={styles.card} onClick={() => alert("Em breve!")}>
                      <img src={hotPotatoImg} alt="Batata Quente" className={styles.cardImage} />
                      <span className={styles.comingSoon}>Em breve</span>
                    </div>
                    <p className={styles.cardTitle}>Batata Quente</p>
                  </div>
                  <div>
                    <div className={styles.card} onClick={() => alert("Em breve!")}>
                      <img src={textCompleteImg} alt="Lacunas" className={styles.cardImage} />
                      <span className={styles.comingSoon}>Em breve</span>
                    </div>
                    <p className={styles.cardTitle}>Lacunas</p>
                  </div>
                  <div>
                    <div className={styles.card} onClick={() => alert("Em breve!")}>
                      <img src={drawImg} alt="Sorteio" className={styles.cardImage} />
                      <span className={styles.comingSoon}>Em breve</span>
                    </div>
                    <p className={styles.cardTitle}>Sorteio</p>
                  </div>
                </div>
              </div>
            ) : (
              !isMobile && (
                <div className={styles.previewSection}>
                  <div className={styles.previewText}>Preview da Interação</div>
                  {interactionMapping[selectedInteraction.type]?.preview &&
                    React.createElement(interactionMapping[selectedInteraction.type].preview, { interaction: selectedInteraction })}
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CreatePresentation;
