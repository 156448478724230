import React, { useEffect, useMemo, useState } from 'react';
import { listenToResponses } from './wordcloud';
import styles from './WordCloudProjection.module.css';
import WordCloudComponent from './WordCloudComponent';

/**
 * Componente de projeção para interações de nuvem de palavras.
 * @param {Object} props - Propriedades do componente.
 * @param {Object} props.interaction - Dados da interação.
 * @param {string} props.pin - PIN da apresentação.
 * @param {string} props.interactionId - ID da interação.
 */
function WordCloudProjection({ interaction, pin, interactionId }) {
  const [responses, setResponses] = useState({});

  useEffect(() => {
    const fetchResponses = async () => {
      const unsubscribe = await listenToResponses(pin, interactionId, setResponses);
      return () => unsubscribe();
    };

    fetchResponses();
  }, [pin, interactionId]);

  const words = useMemo(() => {
    const wordCounts = {};
    Object.values(responses).forEach(response => {
      const word = response.word.toLowerCase();
      wordCounts[word] = (wordCounts[word] || 0) + 1;
    });

    const wordArray = Object.keys(wordCounts).map(word => ({
      text: word,
      value: wordCounts[word]
    }));

    return wordArray;
  }, [responses]);

  return (
    <div className={styles.wordcloudProjectionContainer}>
      <WordCloudComponent words={words} />
    </div>
  );
}

export default WordCloudProjection;
