import React from 'react';
import RefreshIcon from '../../images/control bar icons/refresh-icon.svg';
import TrophyIcon from '../../images/control bar icons/trophy-icon.svg';
import styles from '../../pages/Projection.module.css';

const handleClearResponses = () => {
  console.log('Limpar Respostas');
  // Adicionar lógica para limpar respostas
};

const handleRevealResponses = () => {
  console.log('Revelar Respostas');
  // Adicionar lógica para revelar respostas
};

function WordcloudSpecialButtons() {
  return (
    <>
      <button className={`${styles.controlButton} ${styles.controlPink}` } onClick={handleClearResponses}>
        <img src={RefreshIcon} alt="Limpar Respostas" />
      </button>
      <button className={`${styles.controlButton} ${styles.controlPink}`} onClick={handleRevealResponses}>
        <img src={TrophyIcon} alt="Revelar Respostas" />
      </button>
    </>
  );
}

export default WordcloudSpecialButtons;
