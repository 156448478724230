// src/interactions/insights/InsightsQuickCreateModal.js

import React, { useState } from 'react';
import styles from './InsightsQuickCreateModal.module.css';

/**
 * Componente modal para criação rápida de uma interação de insights.
 * @param {Object} props - Propriedades do componente.
 * @param {boolean} props.show - Indica se o modal deve ser exibido.
 * @param {function} props.onClose - Função chamada ao fechar o modal.
 * @param {function} props.onSave - Função chamada ao salvar a nova interação.
 */
const InsightsQuickCreateModal = ({ show, onClose, onSave }) => {
  const [title, setTitle] = useState('');

  /**
   * Função para salvar a nova interação de insights.
   */
  const handleSave = () => {
    onSave({ title });
  };

  if (!show) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <span className={styles.close} onClick={onClose}>&times;</span>
        <h2>Insights</h2>
        <p>Insira as informações para criar seus insights instantâneos! 🚀</p>
        <form>
          <div className={styles.formGroup}>
            <label htmlFor="title">Título</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <button type="button" className={styles.modalButton} onClick={handleSave}>Salvar</button>
        </form>
      </div>
    </div>
  );
};

export default InsightsQuickCreateModal;
