import React, { useEffect, useState } from 'react';
import { listenToVotes } from './poll';
import styles from './PollProjection.module.css';

function PollProjection({ interaction, pin, interactionId }) {
  const [options, setOptions] = useState(interaction.options);

  useEffect(() => {
    let unsubscribe = () => {};

    const setupListener = async () => {
      unsubscribe = await listenToVotes(pin, interactionId, (updatedOptions) => {
        const updatedWithVotes = interaction.options.map((option, index) => ({
          ...option,
          votes: updatedOptions[index]?.votes || 0
        }));
        setOptions(updatedWithVotes);
      });
    };

    setupListener();

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [pin, interactionId, interaction.options]);

  const totalVotes = options.reduce((sum, option) => sum + (option.votes || 0), 0);
  const maxCount = Math.max(...options.map(option => option.votes || 0), 1);

  return (
    <div className={styles.pollProjection}>
      <div className={styles.chartContainer}>
        <div className={styles.chart}>
          {options.map((option, index) => {
            const voteCount = option.votes || 0;
            const votePercentage = totalVotes > 0 ? (voteCount / totalVotes) * 100 : 0;
            return (
              <div key={index} className={styles.barContainer}>
                <div className={styles.bar} style={{ height: `${votePercentage}%`, backgroundColor: option.color }}>
                  <span className={styles.countLabel}>{voteCount}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.optionLabels}>
        {options.map((option, index) => (
          <p key={index} className={styles.optionLabel} style={{ backgroundColor: option.color }}>{option.text}</p>
        ))}
      </div>
    </div>
  );
}

export default PollProjection;
