import React from 'react';
import Lottie from 'lottie-react';
import styles from './ScoreboardFinal.module.css';
import trophyLottie from '../../../../images/lotties/trofeu-lottie.json';

const ScoreboardFinal = ({ participants }) => {
  const sortedParticipants = participants.sort((a, b) => b.totalScore - a.totalScore);

  const winners = [
    sortedParticipants[1] || null,
    sortedParticipants[0] || null,
    sortedParticipants[2] || null,
  ];

  const maxScore = sortedParticipants[0] ? sortedParticipants[0].totalScore : 1;

  return (
    <div className={styles.scoreboard}>
      <h2>PLACAR FINAL</h2>
      <div className={styles.container}>
        <div className={styles.podium}>
          <div className={styles.trophyContainer}>
            <Lottie animationData={trophyLottie} className={styles.trophyLottie} />
          </div>
          <div className={styles.winners}>
            {winners.map((participant, index) => (
              participant && (
                <div key={participant.name} className={`${styles.winner} ${index === 1 ? styles.gold : index === 0 ? styles.silver : styles.bronze}`}>
                  <div className={styles.barContainer}>
                    <div className={styles.avatarContainer}>
                      <img src={participant.photoURL} alt={participant.name} className={styles.avatar} />
                      <span className={styles.name}>{participant.name}</span>
                    </div>
                    <div className={styles.bar} style={{ height: `calc(${(participant.totalScore / maxScore) * 100}% - 60px)` }}>
                      <span className={styles.score}>{participant.totalScore} pts</span>
                    </div>
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
        <div className={styles.participantListContainer}>
          <ul className={styles.participantList}>
            {sortedParticipants.slice(3).map((participant) => (
              <li key={participant.name} className={styles.participantItem}>
                <img src={participant.photoURL} alt={participant.name} className={styles.avatar} />
                <span className={styles.participantName}>{participant.name}</span>
                <span className={styles.participantScore}>{participant.totalScore} pts</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ScoreboardFinal;
