import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import styles from './Preparing.module.css';

const Preparing = ({ timeRemaining, percentage }) => {
  return (
    <div className={styles.preparing}>
      <h2>A pergunta iniciará em breve, preparem-se!</h2>
      <div className={styles.timerContainer}>
        <CircularProgressbar
          value={percentage}
          text={`${timeRemaining}`}
          styles={buildStyles({
            textColor: '#E65A5A',
            pathColor: '#E65A5A',
            trailColor: '#d6d6d6',
          })}
        />
      </div>
    </div>
  );
};

export default Preparing;
