import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WordCloud from 'react-wordcloud';
import logo from '../images/youpin-logo.png';
import '../assets/wordcloud.css';
import styles from './JoinPresentation.module.css';

const initialWordsList = [
  { text: 'incrível' },
  { text: 'wow!' },
  { text: 'maravilhoso' },
  { text: 'amei 😍' },
  { text: 'engajador' },
  { text: 'quero biz🚀' },
  { text: 'Animal!' },
  { text: 'show' },
  { text: 'sensacional' },
  { text: '👏' },
  { text: '🚀' },
  { text: '🥳' },
  { text: '🤔' },
];

const generateRandomWord = (word) => {
  return { ...word, value: Math.floor(Math.random() * 10) + 1 };
};

const options = {
  colors: ["#E6C65A", "#E65A5A", "#6913C7", "#DA71FF", "#5A7FE6", "#3AAC60"],
  enableTooltip: false,
  deterministic: false,
  fontFamily: "montserrat",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: 900,
  padding: 1,
  rotations: 3,
  rotationAngles: [0, -90, 90],
  scale: "log",
  spiral: "archimedean",
  transitionDuration: 1000,
  enableOptimizations: true
};

function JoinPresentation() {
  const [pin, setPin] = useState('');
  const [words, setWords] = useState([generateRandomWord(initialWordsList[0])]);
  const navigate = useNavigate();

  const handleJoin = (e) => {
    e.preventDefault();
    navigate(`/participate/${pin}`);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setWords(prevWords => {
        const remainingWords = initialWordsList.filter(word => !prevWords.some(w => w.text === word.text));
        if (remainingWords.length > 0) {
          const randomIndex = Math.floor(Math.random() * remainingWords.length);
          const nextWord = generateRandomWord(remainingWords[randomIndex]);
          return [...prevWords, nextWord];
        } else {
          clearInterval(interval);
          return prevWords;
        }
      });
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.joinContainer}>
      <img src={logo} alt="YouPin logo" className={styles.joinLogo} />
      <div className={styles.joinWordcloud}>
        <WordCloud words={words} options={options} />
      </div>
      <div className={styles.joinForm}>
        <input
          type="text"
          placeholder="INSIRA O PIN"
          className={styles.joinInput}
          value={pin}
          onChange={(e) => setPin(e.target.value)}
        />
        <button type="submit" className={styles.joinButton} onClick={handleJoin}>Participar</button>
        <a href="/" className={styles.joinLink}>Ir pra home</a>
      </div>
    </div>
  );
}

export default JoinPresentation;