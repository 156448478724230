import React, { useState, useEffect } from 'react';
import { submitVote, removeVote, listenToVotes } from './poll';
import styles from './PollParticipant.module.css';

function PollParticipant({ interaction, pin, interactionId }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState(interaction.options);
  const maxSelections = interaction.maxSelections || 1;

  useEffect(() => {
    const unsubscribe = listenToVotes(pin, interactionId, (updatedOptions) => {
      const updatedWithVotes = interaction.options.map((option, index) => ({
        ...option,
        votes: updatedOptions[index]?.votes || 0
      }));
      setOptions(updatedWithVotes);
    });

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [pin, interactionId, interaction.options]);

  const handleVote = (optionIndex) => {
    if (selectedOptions.includes(optionIndex)) {
      // Deseleciona a opção se já estiver selecionada
      const newSelectedOptions = selectedOptions.filter(index => index !== optionIndex);
      setSelectedOptions(newSelectedOptions);
      removeVote(pin, interactionId, optionIndex); // Chama a função para remover o voto do banco de dados
    } else if (selectedOptions.length < maxSelections) {
      // Seleciona a nova opção se ainda não atingiu o máximo
      const newSelectedOptions = [...selectedOptions, optionIndex];
      setSelectedOptions(newSelectedOptions);
      submitVote(pin, interactionId, optionIndex);
    }
  };

  const totalVotes = options.reduce((sum, option) => sum + (option.votes || 0), 0);

  return (
    <div className={styles.pollParticipantContainer}>
      {/* Visualização mobile das opções */}
      <div className={styles.chartContainer}>
        <div className={styles.chart}>
          {options.map((option, index) => {
            const voteCount = option.votes || 0;
            const votePercentage = totalVotes > 0 ? (voteCount / totalVotes) * 100 : 0;
            return (
              <div key={index} className={styles.barContainer}>
                <div className={styles.bar} style={{ height: `${votePercentage}%`, backgroundColor: option.color }}></div>
                <span className={styles.countLabel}>{voteCount}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.optionsContainer}>
        {options.map((option, index) => (
          <button
            key={index}
            className={`${styles.optionButton} ${selectedOptions.includes(index) ? styles.selected : ''}`}
            style={{ backgroundColor: option.color }}
            onClick={() => handleVote(index)}
          >
            {option.text}
          </button>
        ))}
      </div>
      <p className={styles.selectionHint}>
        {maxSelections === 1 ? "Selecione uma opção" : `Selecione até ${maxSelections} opções`}
      </p>
    </div>
  );
}

export default PollParticipant;
