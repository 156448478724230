import React from 'react';
import PreviewContainer from '../../components/PreviewContainer';
import styles from './MultimediaPreview.module.css';

const MultimediaPreview = ({ interaction }) => {
  const renderYouTubeEmbed = (url) => {
    let videoId = "";
    const urlObj = new URL(url);

    // Support both "www.youtube.com" and "youtu.be" formats
    if (urlObj.hostname === "www.youtube.com" || urlObj.hostname === "youtube.com") {
      videoId = urlObj.searchParams.get("v");
    } else if (urlObj.hostname === "youtu.be") {
      videoId = urlObj.pathname.slice(1);
    }

    if (!videoId) return <p>URL do YouTube inválido</p>;

    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return (
      <iframe
        width="560"
        height="315"
        src={embedUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  };

  return (
    <PreviewContainer pin="0000">
      <h2>{interaction.title || "Título da Interação Multimídia"}</h2>
      {interaction?.image && <img src={interaction.image} alt="Slide Image" className={styles.multimediaImage} />}
      {interaction?.youtubeLink && renderYouTubeEmbed(interaction.youtubeLink)}
      {interaction?.text && <p className={styles.multimediaText}>{interaction.text}</p>}
      {interaction?.buttonText && interaction?.buttonLink && (
        <a href={interaction.buttonLink} target="_blank" rel="noopener noreferrer" className={styles.multimediaButton}>
          {interaction.buttonText}
        </a>
      )}
    </PreviewContainer>
  );
};

export default MultimediaPreview;
