import React from 'react';

function WordcloudForm({ interaction, handleUpdateInteraction }) {
  return (
    <div>
        <label>Tìtulo:</label>
      <input
        type="text"
        placeholder="Título da Interação"
        value={interaction.title}
        onChange={(e) => handleUpdateInteraction('title', e.target.value)}
      />
      {/* Adicione outros campos específicos para Wordcloud aqui */}
    </div>
  );
}

export default WordcloudForm;
