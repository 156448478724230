// src/interactions/quiz/states/participantQuizStates/WaitingForParticipants.js
import React, { useState } from 'react';
import styles from './WaitingForParticipants.module.css';

// Importando imagens de avatar
import avatar1 from '../../../../images/quiz avatars/Avatar 1.png';
import avatar2 from '../../../../images/quiz avatars/Avatar 2.png';
import avatar3 from '../../../../images/quiz avatars/Avatar 3.png';
import avatar4 from '../../../../images/quiz avatars/Avatar 4.png';
import avatar5 from '../../../../images/quiz avatars/Avatar 5.png';
import avatar6 from '../../../../images/quiz avatars/Avatar 6.png';
import avatar7 from '../../../../images/quiz avatars/Avatar 7.png';
import avatar8 from '../../../../images/quiz avatars/Avatar 8.png';
import avatar9 from '../../../../images/quiz avatars/Avatar 9.png';
import avatar10 from '../../../../images/quiz avatars/Avatar 10.png';
import avatar11 from '../../../../images/quiz avatars/Avatar 11.png';
import avatar12 from '../../../../images/quiz avatars/Avatar 12.png';
import avatar13 from '../../../../images/quiz avatars/Avatar 13.png';
import avatar14 from '../../../../images/quiz avatars/Avatar 14.png';
import avatar15 from '../../../../images/quiz avatars/Avatar 15.png';

const avatarOptions = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
];

const WaitingForParticipants = ({ name, setName, handleSubmitParticipant, isSubmitted }) => {
  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(0);
  const selectedAvatar = avatarOptions[selectedAvatarIndex];

  const handlePreviousAvatar = () => {
    setSelectedAvatarIndex((prevIndex) => (prevIndex === 0 ? avatarOptions.length - 1 : prevIndex - 1));
  };

  const handleNextAvatar = () => {
    setSelectedAvatarIndex((prevIndex) => (prevIndex === avatarOptions.length - 1 ? 0 : prevIndex + 1));
  };

  const handleSubmit = () => {
    handleSubmitParticipant(name, selectedAvatar);
  };

  return (
    <div className={styles.participantContainer}>
      <h2>Inscreva-se para Participar deste Divertido Quiz!</h2>
      {!isSubmitted && (
        <>
          <div className={styles.avatarSelector}>
            <div className={styles.avatars}>
              <img
                src={avatarOptions[(selectedAvatarIndex === 0 ? avatarOptions.length : selectedAvatarIndex) - 1]}
                alt="Previous Avatar"
                className={`${styles.avatar} ${styles.unselected}`}
                onClick={handlePreviousAvatar}
              />
              <img
                src={selectedAvatar}
                alt="Selected Avatar"
                className={`${styles.avatar} ${styles.selected}`}
              />
              <img
                src={avatarOptions[(selectedAvatarIndex + 1) % avatarOptions.length]}
                alt="Next Avatar"
                className={`${styles.avatar} ${styles.unselected}`}
                onClick={handleNextAvatar}
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="participantName">Qual é o seu nome?</label>
            <input
              type="text"
              id="participantName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength={20}
              className={styles.nameInput}
            />
          </div>
        </>
      )}
      {isSubmitted && (
        <div className={styles.avatarContainer}>
          <img src={selectedAvatar} alt="Selected Avatar" className={styles.selectedAvatar} />
          <p className={styles.participantName}>{name}</p>
        </div>
      )}
      <button className={styles.submitButton} onClick={handleSubmit} disabled={!name || isSubmitted}>
        {isSubmitted ? 'Preparado!' : 'Preparar!'}
      </button>
    </div>
  );
};

export default WaitingForParticipants;
