import React, { useState, useEffect } from 'react';
import WaitingForParticipants from './states/participantQuizStates/WaitingForParticipants';
import Preparing from './states/participantQuizStates/Preparing';
import Question from './states/participantQuizStates/Question';
import AnswerRevealed from './states/participantQuizStates/AnswerRevealed';
import ScoreboardFinal from './states/participantQuizStates/ScoreboardFinal';
import Scoreboard from './states/participantQuizStates/Scoreboard';  // Importando o Scoreboard
import { listenToQuizState, submitParticipantInfo, listenToParticipants, submitQuizResponse, getUpdatedParticipant } from './quiz';
import styles from './QuizParticipant.module.css';

// Função para ordenar os participantes por pontuação
const sortParticipants = (participants) => {
  return participants.sort((a, b) => b.totalScore - a.totalScore);
};

const QuizParticipant = ({ pin, interaction }) => {
  const [quizState, setQuizState] = useState(interaction.state.quizState || 'waitingForParticipants');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(interaction.state.currentQuestionIndex || 0);
  const [timeRemaining, setTimeRemaining] = useState(interaction.questions[currentQuestionIndex]?.time || 0);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [participant, setParticipant] = useState(null);
  const [score, setScore] = useState(0);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const unsubscribeQuizState = listenToQuizState(pin, interaction.id, (newQuizState) => {
      setQuizState(newQuizState.quizState);
      setCurrentQuestionIndex(newQuizState.currentQuestionIndex ?? 0);
      if (newQuizState.quizState === 'question') {
        setTimeRemaining(interaction.questions[newQuizState.currentQuestionIndex]?.time || 0);
      } else {
        setTimeRemaining(newQuizState.timeRemaining ?? 0);
      }
      setHasSubmitted(false);
    });

    const unsubscribeParticipants = listenToParticipants(pin, interaction.id, (newParticipants) => {
      setParticipants(newParticipants);
    });

    return () => {
      if (typeof unsubscribeQuizState === 'function') {
        unsubscribeQuizState();
      }
      if (typeof unsubscribeParticipants === 'function') {
        unsubscribeParticipants();
      }
    };
  }, [pin, interaction.id, interaction.questions]);

  useEffect(() => {
    if (quizState === 'waitingForParticipants' && participants.length === 0) {
      setIsSubmitted(false);
      setParticipant(null);
      setName('');
    }
  }, [quizState, participants]);

  useEffect(() => {
    if (participants.length > 0) {
      const currentParticipant = participants.find(p => p.name === name);
      if (currentParticipant) {
        setParticipant(currentParticipant);
      }
    }
  }, [participants, name]);

  const handleSubmitParticipant = (name, selectedAvatar) => {
    const participant = { name, photoURL: selectedAvatar, totalScore: 0, responses: {} };
    submitParticipantInfo(pin, interaction.id, participant).then((participantRef) => {
      setParticipant(participant);
      setIsSubmitted(true);
    });
  };

  const handleOptionClick = async (index, questionScore) => {
    if (hasSubmitted) return;
    setHasSubmitted(true);
    console.log('handleOptionClick called', { index, questionScore });
    if (participant) {
      console.log('Submitting quiz response', { pin, interactionId: interaction.id, currentQuestionIndex, index, participantName: participant.name, questionScore });
      await submitQuizResponse(pin, interaction.id, currentQuestionIndex, index, participant.name, questionScore);
  
      // Recupera os dados atualizados do participante
      const updatedParticipant = await getUpdatedParticipant(pin, interaction.id, participant.name);
      console.log('Updated participant', updatedParticipant);
      setParticipant(updatedParticipant);
      setScore(updatedParticipant.totalScore);
    }
  };

  const renderContent = () => {
    switch (quizState) {
      case 'waitingForParticipants':
        return (
          <WaitingForParticipants
            name={name}
            setName={setName}
            handleSubmitParticipant={handleSubmitParticipant}
            isSubmitted={isSubmitted}
          />
        );
      case 'preparing':
        return <Preparing timeRemaining={timeRemaining} />;
      case 'question':
        const selectedQuestion = interaction.questions[currentQuestionIndex];
        return (
          <Question
            selectedQuestion={selectedQuestion}
            timeRemaining={timeRemaining}
            handleOptionClick={handleOptionClick}
            hasSubmitted={hasSubmitted}
            pin={pin}
            interactionId={interaction.id}
            participantName={name}
          />
        );
      case 'answerRevealed':
        const answeredQuestion = interaction.questions[currentQuestionIndex];
        const response = participant?.responses[currentQuestionIndex];
        const isCorrect = response?.answer === answeredQuestion.correctOption;
        return (
          <AnswerRevealed
            question={answeredQuestion}
            participant={participant}
            score={response?.questionScore}
            totalScore={participant?.totalScore}
            isCorrect={isCorrect}
          />
        );
      case 'scoreboardFinal':
        return <ScoreboardFinal participants={participants} />;
      case 'genericScoreboard':
        return (
          <Scoreboard
            participants={participants}
            sortParticipants={sortParticipants}
          />
        );
      default:
        return null;
    }
  };

  return <div className={styles.quizParticipant}>{renderContent()}</div>;
};

export default QuizParticipant;
