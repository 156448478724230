import React, { useEffect, useState } from 'react';
import styles from './QuizPreview.module.css';
import PreviewContainer from '../../components/PreviewContainer';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function QuizPreview({ interaction }) {
  const selectedQuestionIndex = interaction.selectedQuestionIndex !== undefined ? interaction.selectedQuestionIndex : 0;
  const selectedQuestion = interaction.questions ? interaction.questions[selectedQuestionIndex] : null;
  const [timeRemaining, setTimeRemaining] = useState(selectedQuestion ? selectedQuestion.time : 10);
  const [percentage, setPercentage] = useState(100);

  useEffect(() => {
    if (selectedQuestion) {
      setTimeRemaining(selectedQuestion.time);
      setPercentage(100);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    if (selectedQuestion && timeRemaining > 0) {
      const timer = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
        setPercentage((timeRemaining - 1) / selectedQuestion.time * 100);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [timeRemaining, selectedQuestion]);

  return (
    <PreviewContainer pin="0000">
      <div className={styles.quizPreview}>
        {interaction.questions && interaction.questions.length > 0 ? (
          <>
            <h3 className={styles.questionText}>{selectedQuestion.text}</h3>
            <div className={styles.variableContent}>
              {selectedQuestion.image ? (
                <div className={styles.imageContainer}>
                  <img src={selectedQuestion.image} alt="Imagem da pergunta" className={styles.questionImage} />
                  <div className={styles.timerOverlay}>
                    <CircularProgressbar
                      value={percentage}
                      text={`${timeRemaining}`}
                      styles={buildStyles({
                        textColor: '#E65A5A',
                        pathColor: '#E65A5A',
                        trailColor: '#d6d6d6',
                      })}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.timerContainer}>
                  <CircularProgressbar
                    value={percentage}
                    text={`${timeRemaining}`}
                    styles={buildStyles({
                      textColor: '#E65A5A',
                      pathColor: '#E65A5A',
                      trailColor: '#d6d6d6',
                    })}
                  />
                </div>
              )}
            </div>
            <div className={styles.optionsContainer}>
              {selectedQuestion.options.map((option, index) => (
                <div key={index} className={`${styles.optionItem} ${styles[`optionColor${index + 1}`]}`}>
                  {option}
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className={styles.addQuestionContainer}>
            <p className={styles.addQuestionText}>Adicione uma pergunta</p>
          </div>
        )}
      </div>
    </PreviewContainer>
  );
}

export default QuizPreview;
