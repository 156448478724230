import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import Sidebar from '../components/Sidebar';
import { getPresentations, deletePresentation, duplicatePresentation, createQuickInteractionEvent } from '../firebaseFunctions';
import styles from './AccountHome.module.css';
import { AuthContext } from '../firebaseAuthFunctions';
import nuvemDePalavrasImg from '../images/nuvem-de-palavras-interacao.png';
import multimediaImg from '../images/multimidia-interacao.png';
import pollImg from '../images/pesquisa-interacao.png';
import quizImg from '../images/quiz-interacao.png';
import insightsImg from '../images/insights-interaction.png';
import hotPotatoImg from '../images/hot-potato-interaction.png';
import textCompleteImg from '../images/text-complete-interaction.png';
import drawImg from '../images/draw-interaction.png';
import playIcon from '../images/play-icon.svg';
import duplicateIcon from '../images/duplicate-icon.svg';
import editIcon from '../images/edit-icon.svg';
import deleteIcon from '../images/delete-icon.svg';
import addIcon from '../images/add-icon.svg';
import WordCloudQuickCreateModal from '../interactions/wordcloud/WordCloudQuickCreateModal';
import MultimediaQuickCreateModal from '../interactions/multimedia/MultimediaQuickCreateModal';
import PollQuickCreateModal from '../interactions/poll/PollQuickCreateModal';
import InsightsQuickCreateModal from '../interactions/insights/InsightsQuickCreateModal';
import QuizQuickCreateModal from '../interactions/quiz/QuizQuickCreateModal';

import { ReactComponent as ArrowLeft } from '../images/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../images/arrow-right.svg';

function AccountHome() {
  const [presentations, setPresentations] = useState([]);
  const [userData, setUserData] = useState({});
  const [showWordCloudModal, setShowWordCloudModal] = useState(false);
  const [showMultimediaModal, setShowMultimediaModal] = useState(false);
  const [showPollModal, setShowPollModal] = useState(false);
  const [showInsightsModal, setShowInsightsModal] = useState(false); 
  const [showQuizModal, setShowQuizModal] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const interactionCardsRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentUser) {
          const fetchedPresentations = await getPresentations(currentUser.uid);
          setPresentations(
            fetchedPresentations
              ? Object.entries(fetchedPresentations).map(([id, presentation]) => ({ id, ...presentation }))
              : []
          );

          const db = getDatabase();
          const userRef = ref(db, `users/${currentUser.uid}`);
          const userSnapshot = await get(userRef);
          if (userSnapshot.exists()) {
            const userData = userSnapshot.val();
            setUserData(userData);
          } else {
            console.log("No user data found");
          }
        }
      } catch (error) {
        console.error('Error fetching presentations or user data:', error);
      }
    };
    fetchData();
  }, [currentUser]);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };

  const countInteractions = (interactions) => {
    return interactions ? Object.keys(interactions).length : 0;
  };

  const handleDelete = async (presentationId) => {
    if (currentUser) {
      const success = await deletePresentation(currentUser.uid, presentationId);
      if (success) {
        setPresentations(prevPresentations => prevPresentations.filter(presentation => presentation.id !== presentationId));
      }
    }
  };

  const handleDuplicate = async (presentation) => {
    if (currentUser) {
      const newPresentationId = await duplicatePresentation(currentUser.uid, presentation);
      const newPresentation = { ...presentation, id: newPresentationId, title: `${presentation.title} (Cópia)` };
      setPresentations(prevPresentations => [...prevPresentations, newPresentation]);
    }
  };

  const handleOpenWordCloudModal = () => {
    setShowWordCloudModal(true);
  };

  const handleCloseWordCloudModal = () => {
    setShowWordCloudModal(false);
  };

  const handleSaveWordCloud = async (interactionData) => {
    if (!currentUser) return;
    const { presentationId, pin } = await createQuickInteractionEvent(currentUser.uid, 'wordcloud', interactionData);
    handleCloseWordCloudModal();
    window.open(`/projection/${pin}`, '_blank');
  };

  const handleOpenMultimediaModal = () => {
    setShowMultimediaModal(true);
  };

  const handleCloseMultimediaModal = () => {
    setShowMultimediaModal(false);
  };

  const handleSaveMultimedia = async (interactionData) => {
    if (!currentUser) return;
    const { presentationId, pin } = await createQuickInteractionEvent(currentUser.uid, 'multimedia', interactionData);
    handleCloseMultimediaModal();
    window.open(`/projection/${pin}`, '_blank');
  };

  const handleOpenPollModal = () => {
    setShowPollModal(true);
  };

  const handleClosePollModal = () => {
    setShowPollModal(false);
  };

  const handleSavePoll = async (interactionData) => {
    if (!currentUser) return;
    const { presentationId, pin } = await createQuickInteractionEvent(currentUser.uid, 'poll', interactionData);
    handleClosePollModal();
    window.open(`/projection/${pin}`, '_blank');
  };

  const handleOpenInsightsModal = () => {
    setShowInsightsModal(true);
  };

  const handleCloseInsightsModal = () => {
    setShowInsightsModal(false);
  };

  const handleSaveInsights = async (interactionData) => {
    if (!currentUser) return;
    const { presentationId, pin } = await createQuickInteractionEvent(currentUser.uid, 'insights', interactionData);
    handleCloseInsightsModal();
    window.open(`/projection/${pin}`, '_blank');
  };

  const handleOpenQuizModal = () => {
    setShowQuizModal(true);
  };

  const handleCloseQuizModal = () => {
    setShowQuizModal(false);
  };

  const handleSaveQuiz = async (interactionData) => {
    if (!currentUser) return;
    const { presentationId, pin } = await createQuickInteractionEvent(currentUser.uid, 'quiz', interactionData);
    handleCloseQuizModal();
    window.open(`/projection/${pin}`, '_blank');
  };

  const handleEdit = (presentation) => {
    navigate('/account/create', { state: { presentation } });
  };

  const scrollLeft = () => {
    if (interactionCardsRef.current) {
      interactionCardsRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (interactionCardsRef.current) {
      interactionCardsRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const sortedPresentations = [...presentations].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  const handleAvatarClick = () => {
    navigate('../account/profile');
  };

  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.mainContent}>
        <main className={styles.accountHomeMain}>
          <div className={styles.topSection}>
            <div>
              <h2 className={styles.welcome}>
                Bem vindo, {userData.firstName} {userData.lastName}! <span className={styles.planBadge}>{userData.plan}</span>
              </h2>
            </div>
            <div className={styles.userActions}>
              <button className={styles.upgradeButton}>Upgrade</button>
              <div className={styles.userAvatar} onClick={handleAvatarClick}>
                {userData.photoURL ? (
                  <img src={userData.photoURL} alt="Avatar" className={styles.avatarImage} />
                ) : (
                  userData.firstName && userData.lastName ? `${userData.firstName[0]}${userData.lastName[0]}` : 'TF'
                )}
              </div>
            </div>
          </div>
          <div className={styles.interactionSection}>
            <h3>Inicie uma interação instantânea:</h3>
            <p>Inicie um slide interativo rapidamente sem precisar criar uma apresentação inteira 😁</p>
            <button className={`${styles.scrollButton} ${styles.scrollLeft}`} onClick={scrollLeft}>{<ArrowLeft />}</button>
            <div className={styles.interactionCards} ref={interactionCardsRef}>
              <div className={styles.cardContainer}>
                <div className={styles.card} onClick={handleOpenWordCloudModal}>
                  <img src={nuvemDePalavrasImg} alt="Nuvem de palavras" className={styles.cardImage} />
                </div>
                <p className={styles.cardTitle}>Nuvem de palavras</p>
              </div>

              <div className={styles.cardContainer}>
                <div className={styles.card} onClick={handleOpenQuizModal}>
                  <img src={quizImg} alt="Quiz" className={styles.cardImage} />
                </div>
                <p className={styles.cardTitle}>Quiz</p>
              </div>

              <div className={styles.cardContainer}>
                <div className={styles.card} onClick={handleOpenMultimediaModal}>
                  <img src={multimediaImg} alt="Multimídia" className={styles.cardImage} />
                </div>
                <p className={styles.cardTitle}>Multimídia</p>
              </div>

              <div className={styles.cardContainer}>
                <div className={styles.card} onClick={handleOpenPollModal}>
                  <img src={pollImg} alt="Enquete" className={styles.cardImage} />
                </div>
                <p className={styles.cardTitle}>Enquete</p>
              </div>
              <div className={styles.cardContainer}>
                <div className={styles.card} onClick={handleOpenInsightsModal}>
                  <img src={insightsImg} alt="Insights" className={styles.cardImage} />
                </div>
                <p className={styles.cardTitle}>Insights</p>
              </div>
              
              <div className={styles.cardContainer}>
                <div className={styles.card} onClick={() => alert("Em breve!")}>
                  <img src={hotPotatoImg} alt="Batata Quente" className={styles.cardImage} />
                  <span className={styles.comingSoon}>Em breve</span>
                </div>
                <p className={styles.cardTitle}>Batata Quente</p>
              </div>
              <div className={styles.cardContainer}>
                <div className={styles.card} onClick={() => alert("Em breve!")}>
                  <img src={textCompleteImg} alt="Lacunas" className={styles.cardImage} />
                  <span className={styles.comingSoon}>Em breve</span>
                </div>
                <p className={styles.cardTitle}>Lacunas</p>
              </div>
              <div className={styles.cardContainer}>
                <div className={styles.card} onClick={() => alert("Em breve!")}>
                  <img src={drawImg} alt="Sorteio" className={styles.cardImage} />
                  <span className={styles.comingSoon}>Em breve</span>
                </div>
                <p className={styles.cardTitle}>Sorteio</p>
              </div>
            </div>
            <button className={`${styles.scrollButton} ${styles.scrollRight}`} onClick={scrollRight}>{<ArrowRight />}</button>
          </div>

          <WordCloudQuickCreateModal
            show={showWordCloudModal}
            onClose={handleCloseWordCloudModal}
            onSave={handleSaveWordCloud}
          />

          <MultimediaQuickCreateModal
            show={showMultimediaModal}
            onClose={handleCloseMultimediaModal}
            onSave={handleSaveMultimedia}
          />

          <PollQuickCreateModal
            show={showPollModal}
            onClose={handleClosePollModal}
            onSave={handleSavePoll}
          />

          <InsightsQuickCreateModal
            show={showInsightsModal}
            onClose={handleCloseInsightsModal}
            onSave={handleSaveInsights}
          />

          <QuizQuickCreateModal
            show={showQuizModal}
            onClose={handleCloseQuizModal}
            onSave={handleSaveQuiz}
          />

          <div className={styles.recentPresentations}>
            <div className={styles.presentationsHeader}>
              <div className={styles.recentPresentationHeader}>
                <h3>Apresentações recentes:</h3>
                <p>Aqui estão as apresentações que você criou, atualizou ou executou recentemente 🤩</p>
              </div>
              <button onClick={() => navigate('/account/create')} className={styles.addPresentationButton}>
                <img src={addIcon} alt="Add" /> Nova apresentação
              </button>
            </div>
            <div className={styles.presentationCards}>
              {sortedPresentations && sortedPresentations.length > 0 ? (
                sortedPresentations.map((presentation) => (
                  <div key={presentation.id} className={styles.presentationCard}>
                    <h4>{presentation.title}</h4>
                    <p>{countInteractions(presentation.interactions)} Interações</p>
                    <p>{presentation.description}</p>
                    <div className={styles.presentationActions}>
                      <button onClick={() => window.open(`/projection/${presentation.pin}`, '_blank')} className={styles.playButton}>
                        <img src={playIcon} alt="Play" />
                      </button>
                      <button onClick={() => handleDuplicate(presentation)} className={styles.duplicateButton}>
                        <img src={duplicateIcon} alt="Duplicate" />
                      </button>
                      <button onClick={() => handleEdit(presentation)} className={styles.editButton}>
                        <img src={editIcon} alt="Edit" />
                      </button>
                      <button onClick={() => handleDelete(presentation.id)} className={styles.deleteButton}>
                        <img src={deleteIcon} alt="Delete" />
                      </button>
                    </div>
                    <p className={styles.presentationUpdatedAt}>Última execução: {formatDate(presentation.updatedAt)}</p>
                  </div>
                ))
              ) : (
                <p>Crie sua primeira apresentação! 🚀</p>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AccountHome;
