// src/components/PublicRoute.js

import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from '../firebaseAuthFunctions';

const PublicRoute = ({ children }) => {
  const { currentUser } = useAuth();
  
  return !currentUser ? children : <Navigate to="/account/home" />;
};

export default PublicRoute;
