import React from 'react';

function InsightsForm({ interaction, handleUpdateInteraction }) {
  return (
    <div>
      <label>Título:</label>
      <input
        type="text"
        placeholder="Título da Interação"
        value={interaction.title}
        onChange={(e) => handleUpdateInteraction('title', e.target.value)}
      />
      {/* Adicione outros campos específicos para Insights aqui */}
    </div>
  );
}

export default InsightsForm;
