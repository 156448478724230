// src/pages/Aawards.js

import React from 'react';
import Sidebar from '../components/Sidebar';
import styles from './Awards.module.css';

function Awards() {
  return (
    <div className={styles.awardsContainer}>
      <Sidebar />
      <div className={styles.mainContent}>
      
        <main className={styles.awardsMain}>
          <h2>Recompensas</h2>
          <p>Em breve!</p>
        </main>
       
      </div>
    </div>
  );
}

export default Awards;
