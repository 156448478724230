import React, { useEffect, useState } from 'react';
import { listenToQuizState, updateQuizState, listenToParticipants, listenToQuizResponses } from './quiz';
import WaitingForParticipants from './states/projectionQuizStates/WaitingForParticipants';
import Preparing from './states/projectionQuizStates/Preparing';
import Question from './states/projectionQuizStates/Question';
import AnswerRevealed from './states/projectionQuizStates/AnswerRevealed';
import Scoreboard from './states/projectionQuizStates/Scoreboard';
import ScoreboardFinal from './states/projectionQuizStates/ScoreboardFinal';
import styles from './QuizProjection.module.css';
import 'react-circular-progressbar/dist/styles.css';

const QuizProjection = ({ pin, interaction }) => {
  const [quizState, setQuizState] = useState(interaction.state.quizState || 'waitingForParticipants');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(interaction.state.currentQuestionIndex || 0);
  const [timeRemaining, setTimeRemaining] = useState(interaction.state.timeRemaining || 5);
  const [percentage, setPercentage] = useState(100);
  const [participants, setParticipants] = useState([]);
  const [votes, setVotes] = useState([]);

  useEffect(() => {
    const unsubscribe = listenToQuizState(pin, interaction.id, (newQuizState) => {
      setQuizState(newQuizState.quizState);
      setCurrentQuestionIndex(newQuizState.currentQuestionIndex ?? 0);
      setTimeRemaining(newQuizState.timeRemaining ?? 5);
    });

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [pin, interaction.id]);

  useEffect(() => {
    let timer;
    if (quizState === 'preparing') {
      setTimeRemaining(5); // Reinicia a contagem regressiva
      setPercentage(100);

      timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime > 1) {
            setPercentage((prevTime - 1) / 5 * 100);
            return prevTime - 1;
          } else {
            clearInterval(timer);
            updateQuizState(pin, interaction.id, { quizState: 'question', currentQuestionIndex });
            return 0;
          }
        });
      }, 1000);
    } else if (quizState === 'question') {
      const questionTime = interaction.questions[currentQuestionIndex].time;
      setTimeRemaining(questionTime);
      setPercentage(100);

      timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime > 1) {
            setPercentage((prevTime - 1) / questionTime * 100);
            return prevTime - 1;
          } else {
            clearInterval(timer);
            updateQuizState(pin, interaction.id, { quizState: 'answerRevealed', currentQuestionIndex });
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [quizState, pin, interaction.id, currentQuestionIndex]);

  useEffect(() => {
    if (quizState === 'answerRevealed') {
      listenToQuizResponses(pin, interaction.id, currentQuestionIndex, setVotes);
    }
  }, [quizState, pin, interaction.id, currentQuestionIndex]);

  useEffect(() => {
    if (quizState === 'genericScoreboard' || quizState === 'scoreboardFinal') {
      listenToParticipants(pin, interaction.id, setParticipants);
    }
  }, [quizState, pin, interaction.id]);

  const renderContent = () => {
    switch (quizState) {
      case 'waitingForParticipants':
        return <WaitingForParticipants pin={pin} interactionId={interaction.id} />;
      case 'preparing':
        return <Preparing timeRemaining={timeRemaining} percentage={percentage} />;
      case 'question':
        const question = interaction.questions[currentQuestionIndex];
        return <Question question={question} timeRemaining={timeRemaining} percentage={percentage} />;
      case 'answerRevealed':
        const answeredQuestion = interaction.questions[currentQuestionIndex];
        answeredQuestion.votes = votes; // Adicionar os votos à pergunta
        return <AnswerRevealed question={answeredQuestion} />;
      case 'genericScoreboard':
        return <Scoreboard participants={participants} />;
      case 'scoreboardFinal':
        return <ScoreboardFinal participants={participants} />;
      default:
        return null;
    }
  };

  return <div className={styles.quizProjection}>{renderContent()}</div>;
};

export default QuizProjection;
