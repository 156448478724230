import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Importações do Firebase storage
import styles from './EditQuestionModal.module.css';
import { ReactComponent as DeleteIcon } from '../../images/delete-icon.svg'; // Adicione o ícone de deletar

function EditQuestionModal({ show, question, onSave, onClose }) {
  const [text, setText] = useState(question.text);
  const [options, setOptions] = useState(question.options);
  const [image, setImage] = useState(question.image);
  const [uploading, setUploading] = useState(false); // Estado para controlar o upload
  const [time, setTime] = useState(question.time);
  const [correctOption, setCorrectOption] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    setText(question.text);
    setOptions(question.options);
    setImage(question.image);
    setTime(question.time);
    setCorrectOption(question.correctOption ?? 0);
  }, [question]);

  const handleSave = () => {
    if (!text) {
      setError('O enunciado da questão é obrigatório.');
      return;
    }
    if (options.length < 2 || options.some(option => option.trim() === '')) {
      setError('A questão deve ter pelo menos duas respostas válidas.');
      return;
    }
    if (correctOption === null) {
      setError('É obrigatório ter uma opção correta.');
      return;
    }
    if (!time) {
      setError('O tempo de resposta é obrigatório.');
      return;
    }

    onSave({ ...question, text, options, image, time, correctOption });
  };

  const handleOptionChange = (index, value) => {
    const newOptions = options.map((option, i) => (i === index ? value : option));
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    if (options.length < 4) {
      setOptions([...options, '']);
    }
  };

  const handleRemoveOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
    if (correctOption >= newOptions.length) {
      setCorrectOption(newOptions.length - 1);
    }
  };

  // Função para fazer o upload da imagem
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    const storage = getStorage();
    const storageRef = ref(storage, `images/${file.name}`);

    await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);
    setImage(imageUrl);
    setUploading(false);
  };

  // Função para adicionar um link de imagem
  const handleImageLink = (e) => {
    setImage(e.target.value);
  };

  return (
    <div className={`${styles.modal} ${show ? styles.show : ''}`}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>Editar Questão</h2>
          <button onClick={onClose} className={styles.closeButton}>X</button>
        </div>
        <div className={styles.modalBody}>
          <label>Enunciado da Questão:</label>
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            className={error && !text ? styles.error : ''}
          />
          <div className={styles.imageInputContainer}>
            <div className={styles.imageLinkContainer}>
              <label>Adicionar link da imagem:</label>
              <input
                type="text"
                value={image}
                onChange={handleImageLink}
                className={styles.input}
              />
            </div>
            <div className={styles.imageUploadContainer}>
              <label>Ou fazer upload de imagem:</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </div>
          </div>
          {uploading && <p>Uploading...</p>}
          {image && (
            <>
              <img src={image} alt="Imagem da questão" className={styles.previewImage} />
              <button onClick={() => setImage('')} className={styles.deleteButton}>Remover Imagem</button>
            </>
          )}
          <label>Respostas:</label>
          {options.map((option, index) => (
            <div key={index} className={styles.optionItem}>
              <input
                type="checkbox"
                checked={correctOption === index}
                onChange={() => setCorrectOption(index)}
                className={styles.optionCheckbox}
              />
              <input
                type="text"
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                placeholder={`Resposta ${index + 1}`}
                className={`${styles.optionText} ${error && option.trim() === '' ? styles.error : ''}`}
              />
              <button
                onClick={() => handleRemoveOption(index)}
                className={styles.deleteButton}
                disabled={options.length <= 2}
              >
                <DeleteIcon className={styles.icon} />
              </button>
            </div>
          ))}
          <button onClick={handleAddOption} disabled={options.length >= 4}>Adicionar opção</button>
          <label>Tempo de resposta:</label>
          <input
            type="number"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            className={error && !time ? styles.error : ''}
          /> Segundos
        </div>
        <div className={styles.modalFooter}>
          {error && <div className={styles.errorMessage}>{error}</div>}
          <button onClick={onClose} className={styles.cancelButton}>Cancelar</button>
          <button onClick={handleSave} className={styles.saveButton}>Salvar</button>
        </div>
      </div>
    </div>
  );
}

export default EditQuestionModal;
