// src/components/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';

function PrivateRoute({ children }) {
  const [user, loading, error] = useAuthState(auth);

  // Renderiza um indicador de carregamento enquanto verifica o estado de autenticação
  if (loading) {
    return <div>Loading...</div>;
  }

  // Redireciona para a página de login em caso de erro na verificação de autenticação
  if (error) {
    console.error('Error checking auth state: ', error);
    return <Navigate to="/signin" />;
  }

  // Redireciona para a página de login se o usuário não estiver autenticado
  // Caso contrário, renderiza o componente filho
  return user ? children : <Navigate to="/signin" />;
}

export default PrivateRoute;