// src/interactions/poll/poll.js

import { getDatabase, ref, set, update, get, onValue } from 'firebase/database';
import { getPresentationIdByPin } from '../../firebaseFunctions';
import { database } from '../../firebaseConfig';

// Cria uma nova interação de enquete.
export const createPollInteraction = async (userId, presentationId, interaction) => {
  const db = getDatabase();
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interaction.id}`);
  await set(interactionRef, interaction);
};

// Retorna a estrutura correta de uma interação de enquete.
export const createPollObject = (interaction) => {
  return {
    id: interaction.id,
    type: "poll",
    title: interaction.title || "Título da Enquete",
    options: interaction.options || [],
    maxSelections: interaction.maxSelections || 1,
    createdAt: Date.now()
  };
};

// Atualiza uma interação de enquete existente.
export const updatePollInteraction = async (userId, presentationId, interactionId, interaction) => {
  const db = getDatabase();
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}`);
  await update(interactionRef, interaction);
};

// Remove uma interação de enquete existente.
export const removePollInteraction = async (userId, presentationId, interactionId) => {
  const db = getDatabase();
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}`);
  await set(interactionRef, null);
};

// Função para enviar votos ao banco de dados
export const submitVote = async (pin, interactionId, optionIndex) => {
  const { presentationId, userId } = await getPresentationIdByPin(pin);
  if (!presentationId) throw new Error('Presentation not found for the given PIN');

  const optionRef = ref(database, `presentations/${userId}/${presentationId}/interactions/${interactionId}/options/${optionIndex}`);
  const optionSnapshot = await get(optionRef);
  const optionData = optionSnapshot.val();

  const newVotes = (optionData.votes || 0) + 1;
  await update(optionRef, { votes: newVotes });
};

// Função para remover votos do banco de dados
export const removeVote = async (pin, interactionId, optionIndex) => {
  const { presentationId, userId } = await getPresentationIdByPin(pin);
  if (!presentationId) throw new Error('Presentation not found for the given PIN');

  const optionRef = ref(database, `presentations/${userId}/${presentationId}/interactions/${interactionId}/options/${optionIndex}`);
  const optionSnapshot = await get(optionRef);
  const optionData = optionSnapshot.val();

  const newVotes = (optionData.votes || 0) - 1;
  await update(optionRef, { votes: newVotes });
};

// Função para escutar os votos em tempo real
export const listenToVotes = (pin, interactionId, callback) => {
  const db = getDatabase();
  getPresentationIdByPin(pin).then(({ userId, presentationId }) => {
    const optionsRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/options`);
    return onValue(optionsRef, (snapshot) => {
      const options = snapshot.val() || {};
      callback(Object.values(options));
    });
  }).catch((error) => {
    console.error("Error listening to votes:", error);
  });
};
