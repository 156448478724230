import React, { useState } from 'react';
import styles from './PollQuickCreateModal.module.css';
import { ReactComponent as DeleteIcon } from '../../images/delete-icon.svg';

const PollQuickCreateModal = ({ show, onClose, onSave }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [options, setOptions] = useState([{ text: 'Opção 1', color: '#5A7FE6' }, { text: 'Opção 2', color: '#E65A5A' }]);
  const [maxSelections, setMaxSelections] = useState(1);

  const handleOptionChange = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    const newOptions = [...options, { text: `Opção ${options.length + 1}`, color: getNextColor() }];
    setOptions(newOptions);
  };

  const handleRemoveOption = (index) => {
    if (options.length > 2) {
      const newOptions = options.filter((_, i) => i !== index);
      setOptions(newOptions);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleMaxSelectionsChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setMaxSelections(value);
  };

  const getNextColor = () => {
    const colors = ['#E6C65A', '#D764FF', '#3AAC60', '#E65A5A', '#5A7FE6', '#FF1493'];
    const usedColors = options.map(option => option.color);
    return colors.find(color => !usedColors.includes(color)) || '#5A7FE6';
  };

  const handleSave = () => {
    onSave({ title, description, options, maxSelections });
  };

  if (!show) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <span className={styles.close} onClick={onClose}>&times;</span>
        <h2>Configurações da Votação</h2>
        <form>
          <div className={styles.formGroup}>
            <label htmlFor="title">Título</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={handleTitleChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="description">Descrição</label>
            <input
              type="text"
              id="description"
              value={description}
              onChange={handleDescriptionChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="maxSelections">Quantidade de respostas por participante</label>
            <input
              type="number"
              id="maxSelections"
              value={maxSelections}
              min="1"
              max={options.length}
              onChange={handleMaxSelectionsChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Opções</label>
            {options.map((option, index) => (
              <div key={index} className={styles.optionItem}>
                <input
                  type="color"
                  value={option.color}
                  onChange={(e) => handleOptionChange(index, 'color', e.target.value)}
                  className={styles.colorPicker}
                  style={{ backgroundColor: option.color }}
                />
                <div className={styles.optionContent}>
                  <input
                    type="text"
                    placeholder={`Opção ${index + 1}`}
                    value={option.text}
                    onChange={(e) => handleOptionChange(index, 'text', e.target.value)}
                    className={styles.optionText}
                  />
                </div>
                <button type="button" onClick={() => handleRemoveOption(index)} className={styles.deleteButton} disabled={options.length <= 2}>
                  <DeleteIcon className={styles.deleteIcon} />
                </button>
              </div>
            ))}
            <button type="button" onClick={handleAddOption} className={styles.addOptionButton}>Adicionar Opção</button>
          </div>
          <button type="button" className={styles.modalButton} onClick={handleSave}>Salvar</button>
        </form>
      </div>
    </div>
  );
};

export default PollQuickCreateModal;
