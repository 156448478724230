import React, { useEffect, useState } from 'react';
import styles from './WaitingForParticipants.module.css';
import { listenToParticipants } from '../../quiz';

const WaitingForParticipants = ({ pin, interactionId }) => {
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const unsubscribe = listenToParticipants(pin, interactionId, (newParticipants) => {
      setParticipants(newParticipants);
    });

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [pin, interactionId]);

  return (
    <div className={styles.waiting}>
      <h2>Aguardando participantes...</h2>
      <div className={styles.participantsContainer}>
        {participants.length > 0 ? (
          participants.map((participant, index) => (
            <div key={index} className={styles.participant}>
              <img src={participant.photoURL} alt={`Avatar de ${participant.name}`} className={styles.avatar} />
              <p>{participant.name}</p>
            </div>
          ))
        ) : (
          <p>Nenhum participante cadastrado ainda.</p>
        )}
      </div>
    </div>
  );
};

export default WaitingForParticipants;
