import React from 'react';
import styles from './MultimediaParticipant.module.css';
import { sendEmojiReaction } from './multimedia';

/**
 * Componente de participante para interações multimídia.
 * @param {Object} props - Propriedades do componente.
 * @param {Object} props.interaction - Dados da interação.
 * @param {string} props.pin - PIN da apresentação.
 * @param {string} props.interactionId - ID da interação.
 */
function MultimediaParticipant({ interaction, pin, interactionId }) {

  /**
   * Função para lidar com o clique em um emoji de reação.
   * @param {string} emoji - Emoji clicado pelo participante.
   */
  const handleEmojiClick = (emoji) => {
    if (interactionId) {
      sendEmojiReaction(pin, interactionId, emoji);
    } else {
      console.error('Interaction ID is undefined');
    }
  };

  const renderYouTubeEmbed = (url) => {
    let videoId = "";
    const urlObj = new URL(url);

    if (urlObj.hostname === "www.youtube.com" || urlObj.hostname === "youtube.com") {
      videoId = urlObj.searchParams.get("v");
    } else if (urlObj.hostname === "youtu.be") {
      videoId = urlObj.pathname.slice(1);
    }

    if (!videoId) return <p>URL do YouTube inválido</p>;

    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return (
      <iframe
        width="560"
        height="315"
        src={embedUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  };

  return (
    <div className={styles.multimediaParticipantContainer}>
      {interaction?.image && <img src={interaction.image} alt="Slide Image" className={styles.mediaContent} />}
      {interaction?.youtubeLink && renderYouTubeEmbed(interaction.youtubeLink)}
      {interaction?.text && <p className={styles.mediaText}>{interaction.text}</p>}
      {interaction?.buttonText && interaction?.buttonLink && (
        <a href={interaction.buttonLink} target="_blank" rel="noopener noreferrer" className={styles.mediaButton}>
          {interaction.buttonText}
        </a>
      )}
      <div className={styles.reactionButtons}>
        <button className={styles.reactionButton} onClick={() => handleEmojiClick('👍')}>👍</button>
        <button className={styles.reactionButton} onClick={() => handleEmojiClick('👏')}>👏</button>
        <button className={styles.reactionButton} onClick={() => handleEmojiClick('❤️')}>❤️</button>
        <button className={styles.reactionButton} onClick={() => handleEmojiClick('😁')}>😁</button>
        <button className={styles.reactionButton} onClick={() => handleEmojiClick('🥳')}>🥳</button>
        <button className={styles.reactionButton} onClick={() => handleEmojiClick('🚀')}>🚀</button>
      </div>
    </div>
  );
}

export default MultimediaParticipant;
