// Wordcloud
import WordCloudProjection from './wordcloud/WordCloudProjection';
import WordCloudParticipant from './wordcloud/WordCloudParticipant';
import WordcloudPreview from './wordcloud/WordcloudPreview';

// Multimedia
import MultimediaProjection from './multimedia/MultimediaProjection';
import MultimediaParticipant from './multimedia/MultimediaParticipant';
import MultimediaPreview from './multimedia/MultimediaPreview';

// Poll
import PollProjection from './poll/PollProjection';
import PollParticipant from './poll/PollParticipant';
import PollPreview from './poll/PollPreview';

// Insights
import InsightsProjection from './insights/InsightsProjection';
import InsightsParticipant from './insights/InsightsParticipant';
import InsightsPreview from './insights/InsightsPreview';

// Quiz
import QuizProjection from './quiz/QuizProjection';
import QuizParticipant from './quiz/QuizParticipant';
import QuizPreview from './quiz/QuizPreview';

const interactionMapping = {
  wordcloud: {
    projection: WordCloudProjection,
    participant: WordCloudParticipant,
    preview: WordcloudPreview,
  },
  multimedia: {
    projection: MultimediaProjection,
    participant: MultimediaParticipant,
    preview: MultimediaPreview,
  },
  poll: {
    projection: PollProjection,
    participant: PollParticipant,
    preview: PollPreview,
  },
  insights: {
    projection: InsightsProjection,
    participant: InsightsParticipant,
    preview: InsightsPreview,
  },
  quiz: {
    projection: QuizProjection,
    participant: QuizParticipant,
    preview: QuizPreview,
  },
};

export default interactionMapping;
