import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './AnswerRevealed.module.css';

const AnswerRevealed = ({ question }) => {
  return (
    <div className={styles.quizPreview}>
      <h3 className={styles.questionText}>{question.text}</h3>
      <div className={styles.variableContent}>
        {question.image ? (
          <div className={styles.imageContainer}>
            <img src={question.image} alt="Imagem da pergunta" className={styles.questionImage} />
            <div className={styles.timerOverlay}>
              <CircularProgressbar
                value={0}
                text={`0`}
                styles={buildStyles({
                  textColor: '#E65A5A',
                  pathColor: '#E65A5A',
                  trailColor: '#d6d6d6',
                })}
              />
            </div>
          </div>
        ) : (
          <div className={styles.timerContainer}>
            <CircularProgressbar
              value={0}
              text={`0`}
              styles={buildStyles({
                textColor: '#E65A5A',
                pathColor: '#E65A5A',
                trailColor: '#d6d6d6',
              })}
            />
          </div>
        )}
      </div>
      <div className={styles.optionsContainer}>
        {question.options.map((option, index) => (
          <div
            key={index}
            className={`${styles.optionItem} ${styles[`optionColor${index + 1}`]} ${index === question.correctOption ? styles.correctAnswer : styles.incorrectAnswer}`}
          >
            <span>{option}</span>
            <span className={styles.votes}>{`(${question.votes && question.votes[index] !== undefined ? question.votes[index] : 0} votos)`}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnswerRevealed;
