import { auth } from './firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getDatabase, ref, set, get } from 'firebase/database';

/**
 * Função para criar um novo usuário com email e senha.
 * @param {string} email - O email do novo usuário.
 * @param {string} password - A senha do novo usuário.
 * @returns {Object} - O usuário criado.
 */
export const signupUser = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Adiciona o novo usuário ao banco de dados
    const db = getDatabase();
    await set(ref(db, 'users/' + user.uid), {
      email: user.email,
      firstName: 'Novo',
      lastName: 'Usuário',
      plan: 'free',
    });

    return user;
  } catch (error) {
    console.error('Error signing up: ', error);
    throw error;
  }
};

/**
 * Função para fazer login com email e senha.
 * @param {string} email - O email do usuário.
 * @param {string} password - A senha do usuário.
 * @returns {Object} - O usuário autenticado.
 */
export const signinUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error('Error signing in: ', error);
    throw error;
  }
};

/**
 * Função para fazer login com Google.
 * @returns {Object} - O usuário autenticado.
 */
export const signInWithGoogle = async () => {
  try {
    const googleProvider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;

    // Extrair o nome e sobrenome do displayName
    const displayName = user.displayName || '';
    const [firstName, lastName] = displayName.split(' ');

    // Adiciona ou atualiza o usuário no banco de dados
    const db = getDatabase();
    const userRef = ref(db, 'users/' + user.uid);
    const snapshot = await get(userRef);

    if (!snapshot.exists()) {
      await set(userRef, {
        email: user.email,
        firstName: firstName || '',
        lastName: lastName || '',
        plan: 'free',
      });
    }

    return user;
  } catch (error) {
    console.error('Error signing in with Google: ', error);
    throw error;
  }
};
