// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyADkdMA3pKmg680Y9rGPnngztu9UCw8FCo",
  authDomain: "youpin-e65f5.firebaseapp.com",
  databaseURL: "https://youpin-e65f5-default-rtdb.firebaseio.com",
  projectId: "youpin-e65f5",
  storageBucket: "youpin-e65f5.appspot.com",
  messagingSenderId: "472145201404",
  appId: "1:472145201404:web:beaeedee10d5230254af79",
  measurementId: "G-TF8G0J5NHC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const analytics = getAnalytics(app);

export { auth, database };
