import React, { useState } from 'react';
import EditQuestionModal from './EditQuestionModal';
import styles from './QuizQuickCreateModal.module.css';
import { ReactComponent as EditIcon } from '../../images/edit-icon.svg';
import { ReactComponent as DuplicateIcon } from '../../images/duplicate-icon.svg';
import { ReactComponent as DeleteIcon } from '../../images/delete-icon.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const QuizQuickCreateModal = ({ show, onClose, onSave }) => {
  const [title, setTitle] = useState('');
  const [questions, setQuestions] = useState([]);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);

  const handleAddQuestion = () => {
    setEditingQuestion({ text: '', options: ['', ''], image: '', time: 10, index: questions.length });
  };

  const handleEditQuestion = (index) => {
    setEditingQuestion({ ...questions[index], index });
  };

  const handleSaveQuestion = (question) => {
    const updatedQuestions = [...questions];
    if (question.index === questions.length) {
      updatedQuestions.push(question);
    } else {
      updatedQuestions[question.index] = question;
    }
    setQuestions(updatedQuestions);
    setEditingQuestion(null);
  };

  const handleRemoveQuestion = (index) => {
    if (questions.length > 1) {
      const updatedQuestions = questions.filter((_, i) => i !== index);
      setQuestions(updatedQuestions);
    } else {
      alert('Você deve ter pelo menos uma pergunta no quiz.');
    }
  };

  const handleDuplicateQuestion = (index) => {
    const questionToDuplicate = questions[index];
    const updatedQuestions = [...questions, { ...questionToDuplicate, index: questions.length }];
    setQuestions(updatedQuestions);
  };

  const handleSelectQuestion = (index) => {
    setSelectedQuestionIndex(index);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedQuestions = Array.from(questions);
    const [movedQuestion] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, movedQuestion);

    setQuestions(reorderedQuestions);
  };

  const handleSave = () => {
    onSave({ title, questions });
  };

  if (!show) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <span className={styles.close} onClick={onClose}>&times;</span>
        <h2>Quiz</h2>
        <p>Insira as informações para criar seu quiz instantâneo! 🚀</p>
        <form>
          <div className={styles.formGroup}>
            <label htmlFor="title">Título</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Perguntas:</label>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="questionsList">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {questions.map((question, index) => (
                      <Draggable key={index} draggableId={`question-${index}`} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${styles.questionItem} ${selectedQuestionIndex === index ? styles.selectedItem : ''}`}
                            onClick={() => handleSelectQuestion(index)}
                          >
                            <div className={styles.contentColumn}>
                              <p className={styles.questionText}>{index + 1}. {question.text}</p>
                              <div className={styles.interactionButtons}>
                                <button onClick={(e) => { e.stopPropagation(); handleEditQuestion(index); }} className={styles.editButton}>
                                  <EditIcon className={styles.icon} />
                                </button>
                                <button onClick={(e) => { e.stopPropagation(); handleDuplicateQuestion(index); }} className={styles.duplicateButton}>
                                  <DuplicateIcon className={styles.icon} />
                                </button>
                                <button onClick={(e) => { e.stopPropagation(); handleRemoveQuestion(index); }} className={styles.deleteButton} disabled={questions.length <= 1}>
                                  <DeleteIcon className={styles.icon} />
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <button type="button" onClick={handleAddQuestion} className={styles.addOptionButton}>Adicionar pergunta</button>
          </div>
          <button type="button" className={styles.modalButton} onClick={handleSave}>Salvar</button>
        </form>
        {editingQuestion !== null && (
          <EditQuestionModal
            show={true}
            question={editingQuestion}
            onSave={handleSaveQuestion}
            onClose={() => setEditingQuestion(null)}
          />
        )}
      </div>
    </div>
  );
};

export default QuizQuickCreateModal;
