import { getDatabase, ref, set, push, get, onValue, update, remove } from 'firebase/database';
import { createMultimediaInteraction, createMultimediaObject, updateMultimediaInteraction } from './interactions/multimedia/multimedia';
import { createWordcloudInteraction, createWordcloudObject, updateWordcloudInteraction } from './interactions/wordcloud/wordcloud';
import { createPollInteraction, createPollObject, updatePollInteraction } from './interactions/poll/poll';
import { createInsightsInteraction, createInsightsObject, } from './interactions/insights/insights';
import { createQuizInteraction, createQuizObject } from './interactions/quiz/quiz';
import { database } from './firebaseConfig';

const generatePin = () => Math.floor(1000 + Math.random() * 9000).toString();

export const generateInteractionId = (type) => {
  const timestamp = Date.now();
  const randomComponent = Math.floor(Math.random() * 1000);
  return `${type}-${timestamp}-${randomComponent}`;
};

export const createInteraction = (type) => {
  const interactionId = generateInteractionId(type);
  return {
    id: interactionId,
    type,
    title: 'Título',
  };
};

export const createPresentation = async (userId, title, description) => {
  const db = getDatabase();
  const presentationRef = push(ref(db, `presentations/${userId}`));
  const presentationId = presentationRef.key;
  const pin = generatePin();
  const createdAt = new Date().toISOString();
  const updatedAt = createdAt;

  await set(presentationRef, {
    title,
    description,
    pin,
    createdAt,
    updatedAt,
    currentInteractionIndex: 0,
    interactions: [] // Inicializa com interações vazio
  });

  return presentationId;
};

export const updatePresentation = async (userId, presentationId, title, description) => {
  const db = getDatabase();
  const presentationRef = ref(db, `presentations/${userId}/${presentationId}`);
  const updatedAt = new Date().toISOString();

  await update(presentationRef, {
    title,
    description,
    updatedAt
  });
};

// Adicionar no `addInteractionToPresentation`:
export const addInteractionToPresentation = async (userId, presentationId, interaction) => {
  const db = getDatabase();
  const presentationRef = ref(db, `presentations/${userId}/${presentationId}`);
  const presentationSnapshot = await get(presentationRef);
  const presentationData = presentationSnapshot.val();

  if (!presentationData.interactions) {
    presentationData.interactions = {};
  }

  // Encontre a próxima chave disponível
  const interactionKeys = Object.keys(presentationData.interactions).map(Number);
  const nextKey = interactionKeys.length > 0 ? Math.max(...interactionKeys) + 1 : 0;

  let interactionObject;
  if (interaction.type === 'multimedia') {
    interactionObject = createMultimediaObject({ ...interaction, id: nextKey.toString() });
    await createMultimediaInteraction(userId, presentationId, interactionObject);
  } else if (interaction.type === 'wordcloud') {
    interactionObject = createWordcloudObject({ ...interaction, id: nextKey.toString() });
    await createWordcloudInteraction(userId, presentationId, interactionObject);
  } else if (interaction.type === 'poll') {
    interactionObject = createPollObject({ ...interaction, id: nextKey.toString() });
    await createPollInteraction(userId, presentationId, interactionObject);
  } else if (interaction.type === 'insights') {
    interactionObject = createInsightsObject({ ...interaction, id: nextKey.toString() });
    await createInsightsInteraction(userId, presentationId, interactionObject);
  } else if (interaction.type === 'quiz') {
    interactionObject = createQuizObject({ ...interaction, id: nextKey.toString() });
    await createQuizInteraction(userId, presentationId, interactionObject);
  } else {
    throw new Error(`Unsupported interaction type: ${interaction.type}`);
  }

  // Adicione a nova interação ao objeto
  presentationData.interactions[nextKey] = interactionObject;

  // Atualize a referência no banco de dados
  await update(presentationRef, { interactions: presentationData.interactions });
};




export const updateCurrentInteractionIndex = async (pin, index) => {
  const { presentationId, userId } = await getPresentationIdByPin(pin);
  if (!presentationId) throw new Error('Presentation not found for the given PIN');

  const interactionIndexRef = ref(database, `presentations/${userId}/${presentationId}/currentInteractionIndex`);
  await set(interactionIndexRef, index);
};

export const getPresentationIdByPin = async (pin) => {
  const snapshot = await get(ref(database, 'presentations'));
  const presentations = snapshot.val();
  for (const userId in presentations) {
    const userPresentations = presentations[userId];
    for (const presentationId in userPresentations) {
      if (userPresentations[presentationId].pin === pin) {
        return {
          presentationId,
          userId,
        };
      }
    }
  }
  return null;
};

export const getPresentationById = async (userId, presentationId) => {
  const dbRef = ref(database, `presentations/${userId}/${presentationId}`);
  const snapshot = await get(dbRef);
  const presentationData = snapshot.val();

  // Certifique-se de que o campo interactions esteja presente
  if (!presentationData.interactions) {
    presentationData.interactions = [];
    await set(ref(database, `presentations/${userId}/${presentationId}/interactions`), []);
  }

  return presentationData;
};

export const getPresentations = async (userId) => {
  const dbRef = ref(database, `presentations/${userId}`);
  const snapshot = await get(dbRef);
  return snapshot.val();
};

export const getPresentationByPin = async (pin) => {
  const snapshot = await get(ref(database, 'presentations'));
  const presentations = snapshot.val();
  for (const userId in presentations) {
    const userPresentations = presentations[userId];
    for (const presentationId in userPresentations) {
      if (userPresentations[presentationId].pin === pin) {
        return userPresentations[presentationId];
      }
    }
  }
  return null;
};

export const listenToPresentationChanges = (pin, callback) => {
  const dbRef = ref(database, `presentations`);
  onValue(dbRef, (snapshot) => {
    const presentations = snapshot.val();
    for (const userId in presentations) {
      const userPresentations = presentations[userId];
      for (const presentationId in userPresentations) {
        if (userPresentations[presentationId].pin === pin) {
          callback(userPresentations[presentationId]);
          return;
        }
      }
    }
  });
};

export const listenToCurrentInteractionIndexChanges = (pin, callback) => {
  const dbRef = ref(database, `presentations`);
  onValue(dbRef, (snapshot) => {
    const presentations = snapshot.val();
    for (const userId in presentations) {
      const userPresentations = presentations[userId];
      for (const presentationId in userPresentations) {
        if (userPresentations[presentationId].pin === pin) {
          const currentIndex = userPresentations[presentationId].currentInteractionIndex;
          callback(currentIndex);
          return;
        }
      }
    }
  });
};

export const deletePresentation = async (userId, presentationId) => {
  const confirmDelete = window.confirm("Tem certeza de que deseja excluir esta apresentação? Essa ação não poderá ser desfeita");
  if (!confirmDelete) {
    return false;
  }

  const db = getDatabase();
  const presentationRef = ref(db, `presentations/${userId}/${presentationId}`);
  await remove(presentationRef);
  return true;
};

export const duplicatePresentation = async (userId, presentation) => {
  const db = getDatabase();
  const newPresentationRef = push(ref(db, `presentations/${userId}`));
  const newPresentationId = newPresentationRef.key;
  const newPin = generatePin();
  const createdAt = new Date().toISOString();
  const updatedAt = createdAt;

  const { id, createdAt: originalCreatedAt, ...presentationData } = presentation;

  const duplicatedPresentation = {
    ...presentationData,
    pin: newPin,
    createdAt,
    updatedAt,
    title: `${presentation.title} (Cópia)`
  };

  await set(newPresentationRef, duplicatedPresentation);

  return newPresentationId;
};

// Adicionar no `createQuickInteractionEvent`:
export const createQuickInteractionEvent = async (userId, interactionType, interactionData) => {
  const db = getDatabase();
  const presentationRef = push(ref(db, `presentations/${userId}`));
  const presentationId = presentationRef.key;
  const pin = generatePin();
  const createdAt = new Date().toISOString();

  const eventTitle = `${interactionData.title} (Interação Instantânea)`;

  await set(presentationRef, {
    title: eventTitle,
    description: interactionData.description || '',
    pin,
    createdAt,
    updatedAt: createdAt,
    currentInteractionIndex: 0,
    interactions: {}
  });

  const presentationSnapshot = await get(presentationRef);
  const presentationData = presentationSnapshot.val();

  if (!presentationData.interactions) {
    presentationData.interactions = {};
  }

  const interactionIndex = Object.keys(presentationData.interactions).length;
  const interactionKey = interactionIndex.toString();

  let newInteraction;
  if (interactionType === 'multimedia') {
    newInteraction = createMultimediaObject({
      id: interactionKey,
      title: interactionData.title,
      description: interactionData.description,
      image: interactionData.image,
      text: interactionData.slideDescription,
      buttonText: interactionData.buttonText,
      buttonLink: interactionData.buttonLink,
      createdAt
    });
    await createMultimediaInteraction(userId, presentationId, newInteraction);
  } else if (interactionType === 'wordcloud') {
    newInteraction = createWordcloudObject({
      id: interactionKey,
      title: interactionData.title,
      description: interactionData.description,
      createdAt
    });
    await createWordcloudInteraction(userId, presentationId, newInteraction);
  } else if (interactionType === 'poll') {
    newInteraction = createPollObject({
      id: interactionKey,
      title: interactionData.title,
      description: interactionData.description,
      options: interactionData.options,
      maxSelections: interactionData.maxSelections,
      createdAt
    });
    await createPollInteraction(userId, presentationId, newInteraction);
  } else if (interactionType === 'insights') {
    newInteraction = createInsightsObject({
      id: interactionKey,
      title: interactionData.title,
      description: interactionData.description,
      createdAt
    });
    await createInsightsInteraction(userId, presentationId, newInteraction);
  } else if (interactionType === 'quiz') {
    newInteraction = createQuizObject({
      id: interactionKey,
      title: interactionData.title,
      questions: interactionData.questions,
      createdAt
    });
    await createQuizInteraction(userId, presentationId, newInteraction);
  } else {
    throw new Error(`Unsupported interaction type: ${interactionType}`);
  }

  presentationData.interactions[interactionKey] = newInteraction;
  await update(presentationRef, { interactions: presentationData.interactions });

  return { presentationId, pin };
};





export const updateInteractionInPresentation = async (userId, presentationId, interaction) => {
  const db = getDatabase();
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interaction.id}`);
  await update(interactionRef, interaction);
};

export const removeInteraction = async (userId, presentationId, interactionId) => {
  const db = getDatabase();
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}`);
  await set(interactionRef, null);
};

export const duplicateInteraction = async (userId, presentationId, interaction) => {
  const db = getDatabase();
  const newInteractionId = generateInteractionId(interaction.type);
  const newInteraction = { ...interaction, id: newInteractionId };
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${newInteractionId}`);
  await set(interactionRef, newInteraction);
  return newInteraction;
};