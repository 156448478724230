import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './Question.module.css';

const Question = ({ selectedQuestion, timeRemaining, handleOptionClick, hasSubmitted, pin, interactionId, participantName }) => {
  const [currentQuestionTime, setCurrentQuestionTime] = useState(timeRemaining);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentQuestionTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const calculateScore = (totalTime, remainingTime) => {
    const percentageRemaining = (remainingTime / totalTime) * 100;
    const score = Math.max(100 - Math.floor((totalTime - remainingTime) / totalTime * 100), 0);
    console.log(`Calculating score: totalTime=${totalTime}, remainingTime=${remainingTime}, score=${score}`);
    return score;
  };

  const handleOptionClickInternal = (index) => {
    if (!hasSubmitted) {
      console.log('handleOptionClickInternal called', { index });
      const score = selectedQuestion.correctOption === index ? calculateScore(selectedQuestion.time, currentQuestionTime) : 0;
      console.log('Calculated score', { score });
      handleOptionClick(index, score);
    }
  };

  return (
    <>
      <h2 className={styles.questionText}>{selectedQuestion.text}</h2>
      <div className={styles.timerContainer}>
        <CircularProgressbar
          value={(currentQuestionTime / selectedQuestion.time) * 100}
          text={`${currentQuestionTime}`}
          styles={buildStyles({
            textColor: '#E65A5A',
            pathColor: '#E65A5A',
            trailColor: '#d6d6d6',
          })}
        />
      </div>
      {selectedQuestion.image && <img src={selectedQuestion.image} alt="Imagem da pergunta" className={styles.questionImage} />}
      <div className={styles.optionsContainer}>
        {selectedQuestion.options.map((option, index) => (
          <button
            key={index}
            className={`${styles.optionButton} ${styles[`optionColor${index + 1}`]}`}
            onClick={() => handleOptionClickInternal(index)}
            disabled={hasSubmitted}
          >
            {option}
          </button>
        ))}
      </div>
    </>
  );
};

export default Question;
