import React from 'react';
import RefreshIcon from '../../images/control bar icons/refresh-icon.svg';
import TrophyIcon from '../../images/control bar icons/trophy-icon.svg';
import styles from '../../pages/Projection.module.css';

const handlePollAction1 = () => {
  console.log('Ação Especial Poll 1');
  // Adicionar lógica para ação especial Poll 1
};

const handlePollAction2 = () => {
  console.log('Ação Especial Poll 2');
  // Adicionar lógica para ação especial Poll 2
};

function PollSpecialButtons() {
  return (
    <>
      <button className={`${styles.controlButton} ${styles.controlPink}` } onClick={handlePollAction1}>
        <img src={RefreshIcon} alt="Ação Especial Poll 1" />
      </button>
      <button className={`${styles.controlButton} ${styles.controlPink}` } onClick={handlePollAction2}>
        <img src={TrophyIcon} alt="Ação Especial Poll 2" />
      </button>
    </>
  );
}

export default PollSpecialButtons;
