import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Signup.module.css';
import { Link } from 'react-router-dom';
import logo from '../images/youpin-logo.png';
import googleIcon from '../images/google-icon.svg';
import { signupUser, signInWithGoogle } from '../userFunctions'; // Importa a função de login com Google

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    if (password !== confirmPassword) {
      setError('As senhas não coincidem');
      return;
    }
    try {
      await signupUser(email, password);
      navigate('/account/home');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignin = async () => {
    try {
      await signInWithGoogle();
      navigate('/account/home');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className={styles.signupPage}>
      <div className={styles.signupFormContainer}>
        <Link to={"/"}>
          <img src={logo} alt="YouPin Logo" className={styles.signupLogo} />
        </Link>
        <h2>Crie sua conta no YouPin! 😊</h2>
        <p>Entre com seu e-mail e crie uma senha</p>
        {error && <p className={styles.errorMessage}>{error}</p>}
        <form onSubmit={handleSignup}>
          <input
            type="email"
            placeholder="Email"
            className={styles.signupInput}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Senha"
            className={styles.signupInput}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="Confirme sua senha"
            className={styles.signupInput}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button type="submit" className={styles.signupButton}>Cadastrar</button>
          <div className={styles.signinDivider}>
            <span>OU</span>
          </div>
          <button type="button" className={styles.signinGoogleButton} onClick={handleGoogleSignin}>
            <img src={googleIcon} alt="Google Icon" />
            Entre com sua conta do Google
          </button>
        </form>

        <div className={styles.divisor}></div>

        <p className={styles.loginPrompt}>Já é cadastrado?</p>
        <Link to="/signin" className={styles.loginLink}>Fazer login</Link>
      </div>
    </div>
  );
}

export default Signup;
