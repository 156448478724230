import React, { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import necessário
import styles from './MultimediaQuickCreateModal.module.css';

/**
 * Componente modal para criação rápida de uma interação multimídia.
 * @param {Object} props - Propriedades do componente.
 * @param {boolean} props.show - Indica se o modal deve ser exibido.
 * @param {function} props.onClose - Função chamada ao fechar o modal.
 * @param {function} props.onSave - Função chamada ao salvar a nova interação.
 */
const MultimediaQuickCreateModal = ({ show, onClose, onSave }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [slideDescription, setSlideDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonLink, setButtonLink] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [uploading, setUploading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(''); // Guarda a opção selecionada pelo usuário

  /**
   * Função para salvar a nova interação multimídia.
   */
  const handleSave = () => {
    onSave({ title, description, image, slideDescription, buttonText, buttonLink, youtubeLink });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    const storage = getStorage();
    const storageRef = ref(storage, `images/${file.name}`);

    await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);
    setImage(imageUrl);
    setUploading(false);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option === 'imageLink' || option === 'upload') {
      setYoutubeLink('');
    } else if (option === 'youtubeLink') {
      setImage('');
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <span className={styles.close} onClick={onClose}>&times;</span>
        <h2>Configurações da Interação Multimídia</h2>
        <form>
          <div className={styles.formGroup}>
            <label htmlFor="title">Título</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="description">Descrição</label>
            <input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Escolha uma opção:</label>
            <select onChange={(e) => handleOptionChange(e.target.value)} value={selectedOption}>
              <option value="">Selecione</option>
              <option value="imageLink">URL da Imagem</option>
              <option value="upload">Upload da Imagem</option>
              <option value="youtubeLink">URL do YouTube</option>
            </select>
          </div>
          {selectedOption === 'imageLink' && (
            <div className={styles.formGroup}>
              <label htmlFor="image">URL da Imagem</label>
              <input
                type="text"
                id="image"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
            </div>
          )}
          {selectedOption === 'upload' && (
            <div className={styles.formGroup}>
              <label htmlFor="upload">Upload da Imagem</label>
              <input
                type="file"
                id="upload"
                accept="image/*"
                onChange={handleImageUpload}
              />
              {uploading && <p>Uploading...</p>}
            </div>
          )}
          {selectedOption === 'youtubeLink' && (
            <div className={styles.formGroup}>
              <label htmlFor="youtubeLink">URL do Vídeo do YouTube</label>
              <input
                type="text"
                id="youtubeLink"
                value={youtubeLink}
                onChange={(e) => setYoutubeLink(e.target.value)}
              />
            </div>
          )}
          <div className={styles.formGroup}>
            <label htmlFor="slideDescription">Descrição do Slide</label>
            <input
              type="text"
              id="slideDescription"
              value={slideDescription}
              onChange={(e) => setSlideDescription(e.target.value)}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="buttonText">Texto do Botão</label>
            <input
              type="text"
              id="buttonText"
              value={buttonText}
              onChange={(e) => setButtonText(e.target.value)}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="buttonLink">Link do Botão</label>
            <input
              type="text"
              id="buttonLink"
              value={buttonLink}
              onChange={(e) => setButtonLink(e.target.value)}
            />
          </div>
          <button type="button" className={styles.modalButton} onClick={handleSave}>Salvar</button>
        </form>
      </div>
    </div>
  );
};

export default MultimediaQuickCreateModal;
