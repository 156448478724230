import React from 'react';
import WordCloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css'; // Opcional: para habilitar tooltips
import 'tippy.js/animations/scale.css'; // Opcional: para animações de tooltips
import './WordCloudComponent.css'; // Adicione seu CSS personalizado aqui

const WordCloudComponent = ({ words }) => {
  const isMobile = window.innerWidth < 600;

  const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
    fontSizes: isMobile ? [40, 100] : [80, 200], // Ajuste os tamanhos da fonte conforme necessário
    fontFamily: 'Montserrat',
    fontWeight: 900,
    colors: ['#61dafb', '#282c34', '#1f77b4', '#ff7f0e', '#2ca02c'],
    enableTooltip: true,
    deterministic: false,
    transitionDuration: 1000,
  };

  return (
    <div style={{ width: '100%', height: '500px' }}>
      <WordCloud words={words} options={options} />
    </div>
  );
};

export default WordCloudComponent;
