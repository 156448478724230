import React, { useState, useEffect } from 'react';
import PreviewContainer from '../../components/PreviewContainer';
import styles from './InsightsPreview.module.css';

const InsightsPreview = ({ interaction }) => {
  const initialData = [
    { text: "Engajar o público com atividades interativas aumenta a retenção de informações.", value: 30 },
    { text: "Apresentações dinâmicas tornam os eventos mais memoráveis.", value: 20 },
    { text: "Interações ao vivo mantêm a atenção do público e promovem a participação.", value: 25 },
    { text: "Ferramentas interativas ajudam a esclarecer dúvidas em tempo real.", value: 15 },
    { text: "Sessões interativas podem aumentar a satisfação do público.", value: 10 },
    { text: "Estimular a colaboração através de atividades interativas fortalece o aprendizado.", value: 35 },
    { text: "Feedback instantâneo permite ajustes imediatos e melhora a experiência.", value: 5 },
    { text: "Incorporar jogos e quizzes torna o conteúdo mais atraente.", value: 40 }
  ];

  const [data, setData] = useState([]);
  const [widths, setWidths] = useState({});
  const [colors, setColors] = useState({});
  const [previousColorIndex, setPreviousColorIndex] = useState(null);
  const colorPalette = ['#E6C65A', '#D764FF', '#3AAC60', '#E65A5A', '#5A7FE6'];

  useEffect(() => {
    // Embaralha os dados iniciais
    const shuffledData = initialData.sort(() => Math.random() - 0.5);
    setData(shuffledData.slice(0, 1)); // Inicializa com um item

    const interval = setInterval(() => {
      setData(prevData => {
        if (prevData.length < shuffledData.length) {
          return [...prevData, shuffledData[prevData.length]];
        } else {
          clearInterval(interval); // Para o intervalo quando todos os dados forem adicionados
          return prevData;
        }
      });
    }, 3000); // Adiciona um novo texto a cada 3 segundos

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, []);

  useEffect(() => {
    setWidths(prevWidths => {
      const newWidths = { ...prevWidths };
      data.forEach((item, index) => {
        if (!newWidths[item.text]) {
          newWidths[item.text] = getRandomWidthClass();
        }
      });
      return newWidths;
    });

    setColors(prevColors => {
      const newColors = { ...prevColors };
      data.forEach((item, index) => {
        if (!newColors[item.text]) {
          newColors[item.text] = getRandomColor();
        }
      });
      return newColors;
    });
  }, [data]);

  const getRandomWidthClass = () => {
    const widthClasses = [styles.widthSmall, styles.widthMedium, styles.widthLarge];
    return widthClasses[Math.floor(Math.random() * widthClasses.length)];
  };

  const getRandomColor = () => {
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * colorPalette.length);
    } while (newIndex === previousColorIndex);
    setPreviousColorIndex(newIndex);
    return colorPalette[newIndex];
  };

  return (
    <PreviewContainer pin="0000">
      <h2>{interaction.title || "Título do Insight"}</h2>
      <div className={styles.insightsContainer}>
        {data.map((item, index) => (
          <div
            key={index}
            className={`${styles.insightItem} ${widths[item.text]}`}
            style={{ backgroundColor: colors[item.text] }}
          >
            {item.text}
          </div>
        ))}
      </div>
    </PreviewContainer>
  );
};

export default InsightsPreview;
