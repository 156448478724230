// src/firebaseAuthFunctions.js

import { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './firebaseConfig';
import React from 'react';

// Cria um contexto para gerenciar o estado de autenticação
export const AuthContext = createContext();

// Provider para gerenciar o estado de autenticação e fornecer aos componentes filhos
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // Define um observador de mudanças de autenticação
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook para usar o contexto de autenticação
export const useAuth = () => useContext(AuthContext);

// Função para sair da conta
export const logout = () => signOut(auth);