// src/pages/AiAssistant.js

import React from 'react';
import Sidebar from '../components/Sidebar';
import styles from './AiAssistant.module.css';

function AiAssistant() {
  return (
    <div className={styles.aiAssistantContainer}>
      <Sidebar />
      <div className={styles.mainContent}>
        
        <main className={styles.aiAssistantMain}>
          <h2>Assistente I.A</h2>
          <p>Em breve!</p>
        </main>
      </div>
    </div>
  );
}

export default AiAssistant;
