import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './HeaderGlobal.module.css';
import logo from '../images/youpin-logo.png';
import menuIcon from '../images/menu-icon.svg'; // Adicione um ícone de menu
import closeIcon from '../images/close-icon.svg'; // Adicione um ícone de fechamento
import accountIcon from '../images/account-icon.svg'; // Adicione um ícone de fechamento

const HeaderGlobal = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className={styles.homeHeader}>
      <div className={styles.logo}>
        <img src={logo} alt="YouPin Logo" />
      </div>
      <div className={styles.menuIcon} onClick={toggleMenu}>
        <img src={menuIcon} alt="Menu" />
      </div>
      <div className={styles.accountIcon} onClick={null}>
        <Link to="/signin">
          <img src={accountIcon} alt="account" />
        </Link>
      </div>
      <nav className={`${styles.homeNav} ${isMenuOpen ? styles.open : ''}`}>
        <Link to="#">Trabalho</Link>
        <Link to="#">Educação</Link>
        <Link to="#">Produto</Link>
        <Link to="#">Preços</Link>
        <Link to="#">Fale com Vendas</Link>
        <div className={styles.actionButtons}>
          <Link to="/signup" className={styles.highlightButton}>Cadastre-se grátis</Link>
          <Link to="/signin" className={styles.signInButton}>Entrar</Link>
        </div>
      </nav>
      <div className={`${styles.mobileMenu} ${isMenuOpen ? styles.open : ''}`}>
        <div className={styles.mobileMenuHeader}>
          <div className={styles.logo}>
            <img src={logo} alt="YouPin Logo" />
          </div>
          <div className={styles.closeIcon} onClick={closeMenu}>
            <img src={closeIcon} alt="Fechar" />
          </div>
        </div>
        <nav className={styles.mobileNav}>
          <Link to="#" onClick={closeMenu}>Trabalho</Link>
          <Link to="#" onClick={closeMenu}>Educação</Link>
          <Link to="#" onClick={closeMenu}>Produto</Link>
          <Link to="#" onClick={closeMenu}>Preços</Link>
          <Link to="#" onClick={closeMenu}>Fale com Vendas</Link>
        </nav>
        <div className={styles.mobileActionButtons}>

          <Link to="/create" className={styles.signInButton} onClick={closeMenu}>Entrar</Link>
          <Link to="/signup" className={styles.highlightButton} onClick={closeMenu}>Cadastre-se grátis</Link>
        </div>
      </div>
    </header>
  );
};

export default HeaderGlobal;
