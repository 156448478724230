import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './Question.module.css';

const Question = ({ question }) => {
  const initialTime = question.time; // Garanta que estamos pegando o tempo inicial da pergunta
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [percentage, setPercentage] = useState(100);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
        setPercentage(((timeRemaining - 1) / initialTime) * 100);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [timeRemaining, initialTime]);

  return (
    <div className={styles.quizPreview}>
      <h2 className={styles.questionText}>{question.text}</h2>
      {question.image ? (
        <div className={styles.imageContainer}>
          <img src={question.image} alt="Imagem da pergunta" className={styles.questionImage} />
          <div className={styles.timerOverlay}>
            <CircularProgressbar
              value={percentage}
              text={`${timeRemaining}`}
              styles={buildStyles({
                textColor: '#E65A5A',
                pathColor: '#E65A5A',
                trailColor: '#d6d6d6',
              })}
            />
          </div>
        </div>
      ) : (
        <div className={styles.timerContainer}>
          <CircularProgressbar
            value={percentage}
            text={`${timeRemaining}`}
            styles={buildStyles({
              textColor: '#E65A5A',
              pathColor: '#E65A5A',
              trailColor: '#d6d6d6',
            })}
          />
        </div>
      )}
      <div className={styles.optionsContainer}>
        {question.options.map((option, index) => (
          <div key={index} className={`${styles.optionItem} ${styles[`optionColor${index + 1}`]}`}>
            {option}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Question;
