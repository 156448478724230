// src/pages/Plans.js

import React from 'react';
import Sidebar from '../components/Sidebar';
import styles from './Plans.module.css';

function Plans() {
  return (
    <div className={styles.plansContainer}>
      <Sidebar />
      <div className={styles.mainContent}>
      
        <main className={styles.plansMain}>
          <h2>Planos</h2>
          <p>Em breve!</p>
        </main>
       
      </div>
    </div>
  );
}

export default Plans;
