import React, { useState } from 'react';
import EditQuestionModal from './EditQuestionModal';
import styles from './QuizForm.module.css';
import { ReactComponent as EditIcon } from '../../images/edit-icon.svg';
import { ReactComponent as DuplicateIcon } from '../../images/duplicate-icon.svg';
import { ReactComponent as DeleteIcon } from '../../images/delete-icon.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function QuizForm({ interaction, handleUpdateInteraction }) {
  const [title, setTitle] = useState(interaction.title || '');
  const [questions, setQuestions] = useState(interaction.questions || []);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(interaction.selectedQuestionIndex || 0);

  const handleAddQuestion = () => {
    setEditingQuestion({ text: '', options: ['', ''], image: '', time: 10, index: questions.length });
  };

  const handleEditQuestion = (index) => {
    setEditingQuestion({ ...questions[index], index });
  };

  const handleSaveQuestion = (question) => {
    const updatedQuestions = [...questions];
    if (question.index === questions.length) {
      updatedQuestions.push(question);
    } else {
      updatedQuestions[question.index] = question;
    }
    setQuestions(updatedQuestions);
    setEditingQuestion(null);
    handleUpdateInteraction('questions', updatedQuestions);
  };

  const handleRemoveQuestion = (index) => {
    if (questions.length > 1) {
      const updatedQuestions = questions.filter((_, i) => i !== index);
      setQuestions(updatedQuestions);
      handleUpdateInteraction('questions', updatedQuestions);
    } else {
      alert('Você deve ter pelo menos uma pergunta no quiz.');
    }
  };

  const handleDuplicateQuestion = (index) => {
    const questionToDuplicate = questions[index];
    const updatedQuestions = [...questions, { ...questionToDuplicate, index: questions.length }];
    setQuestions(updatedQuestions);
    handleUpdateInteraction('questions', updatedQuestions);
  };

  const handleSelectQuestion = (index) => {
    setSelectedQuestionIndex(index);
    handleUpdateInteraction('selectedQuestionIndex', index);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedQuestions = Array.from(questions);
    const [movedQuestion] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, movedQuestion);

    setQuestions(reorderedQuestions);
    handleUpdateInteraction('questions', reorderedQuestions);
  };

  return (
    <div className={styles.quizForm}>
      <label>Título:</label>
      <input
        type="text"
        placeholder="Título do Quiz"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
          handleUpdateInteraction('title', e.target.value);
        }}
      />
      <label>Perguntas:</label>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="questionsList">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {questions.map((question, index) => (
                <Draggable key={index} draggableId={`question-${index}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`${styles.questionItem} ${selectedQuestionIndex === index ? styles.selectedItem : ''}`}
                      onClick={() => handleSelectQuestion(index)}
                    >
                      <div className={styles.contentColumn}>
                        <p className={styles.questionText}>{index + 1}. {question.text}</p>
                        <div className={styles.interactionButtons}>
                          <button onClick={(e) => { e.stopPropagation(); handleEditQuestion(index); }} className={styles.editButton}>
                            <EditIcon className={styles.icon} />
                          </button>
                          <button onClick={(e) => { e.stopPropagation(); handleDuplicateQuestion(index); }} className={styles.duplicateButton}>
                            <DuplicateIcon className={styles.icon} />
                          </button>
                          <button onClick={(e) => { e.stopPropagation(); handleRemoveQuestion(index); }} className={styles.deleteButton} disabled={questions.length <= 1}>
                            <DeleteIcon className={styles.icon} />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <button onClick={handleAddQuestion} className={styles.addOptionButton}>Adicionar pergunta</button>

      {editingQuestion !== null && (
        <EditQuestionModal
          show={true}
          question={editingQuestion}
          onSave={handleSaveQuestion}
          onClose={() => setEditingQuestion(null)}
        />
      )}
    </div>
  );
}

export default QuizForm;
