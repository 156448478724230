import React from 'react';
import styles from './FooterSite.module.css';
import logo from '../images/youpin-logo.png'; // Ajuste conforme o caminho correto da imagem do logo
import instagramIcon from '../images/instagram-icon.svg'; // Ajuste conforme o caminho correto do ícone do Instagram
import facebookIcon from '../images/facebook-icon.svg'; // Ajuste conforme o caminho correto do ícone do Facebook
import linkedinIcon from '../images/linkedin-icon.svg'; // Ajuste conforme o caminho correto do ícone do LinkedIn

const FooterSite = () => {
  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.footerContainer}>
          <div className={styles.footerLogo}>
            <img src={logo} alt="YouPin Logo" />
            <p>interação e diversão</p>
            <div className={styles.footerSocials}>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <img src={instagramIcon} alt="Instagram" />
              </a>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <img src={facebookIcon} alt="Facebook" />
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                <img src={linkedinIcon} alt="LinkedIn" />
              </a>
            </div>
          </div>
          <div className={styles.footerLinks}>
            <div className={styles.footerColumn}>
              <h4>Produto</h4>
              <ul>
                <li><a href="#">Geral</a></li>
                <li><a href="#">Apresentação interativa</a></li>
                <li><a href="#">Nuvem de Palavras</a></li>
                <li><a href="#">Quiz</a></li>
                <li><a href="#">Q&A</a></li>
                <li><a href="#">Pesquisa</a></li>
                <li><a href="#">Texto aberto</a></li>
                <li><a href="#">Lacunas</a></li>
                <li><a href="#">Batata Quente</a></li>
                <li><a href="#">Formação de Equipes</a></li>
                <li><a href="#">Sorteio</a></li>
              </ul>
            </div>
            <div className={styles.footerColumn}>
              <h4>Recursos</h4>
              <ul>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Como funciona</a></li>
                <li><a href="#">Corporativo</a></li>
                <li><a href="#">Educação</a></li>
                <li><a href="#">Comunidade</a></li>
              </ul>
            </div>
            <div className={styles.footerColumn}>
              <h4>Detalhes</h4>
              <ul>
                <li><a href="#">Legal</a></li>
                <li><a href="#">Políticas</a></li>
                <li><a href="#">Acessibilidade</a></li>
                <li><a href="#">Central de Ajuda</a></li>
                <li><a href="#">Cookies</a></li>
              </ul>
            </div>
            <div className={styles.footerColumn}>
              <h4>Sobre nós</h4>
              <ul>
                <li><a href="#">Mídia</a></li>
                <li><a href="#">Equipe</a></li>
                <li><a href="#">Trabalhe conosco</a></li>
                <li><a href="#">Investidores</a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className={styles.signature}>
        <p>Este site foi projetado com <span role="img" aria-label="coração">❤️</span> por <a href="https://thiagovitordesign.com" target="_blank" rel="noopener noreferrer">Thiago Vitor Design</a></p>
      </div>
    </>
  );
};

export default FooterSite;
