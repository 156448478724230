import React, { useEffect, useState, useRef } from 'react';
import { listenToEmojiReactions } from './multimedia';
import styles from './MultimediaProjection.module.css';

function MultimediaProjection({ interaction, pin, interactionId }) {
  const [emojiReactions, setEmojiReactions] = useState([]);
  const reactionTimeouts = useRef({});
  const unsubscribeRef = useRef(null);

  const renderYouTubeEmbed = (url) => {
    let videoId = "";
    const urlObj = new URL(url);

    if (urlObj.hostname === "www.youtube.com" || urlObj.hostname === "youtube.com") {
      videoId = urlObj.searchParams.get("v");
    } else if (urlObj.hostname === "youtu.be") {
      videoId = urlObj.pathname.slice(1);
    }

    if (!videoId) return <p>URL do YouTube inválido</p>;

    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return (
      <iframe
        width="560"
        height="315"
        src={embedUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  };

  useEffect(() => {
    if (!interactionId) return;

    const handleReaction = (reaction) => {
      const newReactionTimestamp = Date.now();
      const reactionId = newReactionTimestamp + Math.random();

      const newReaction = { 
        id: reactionId, 
        emoji: reaction.emoji, 
        timestamp: newReactionTimestamp,
        position: { left: `${Math.random() * 90}%`, top: `${Math.random() * 90}%` } 
      };

      setEmojiReactions((prevReactions) => [...prevReactions, newReaction]);

      const timeoutId = setTimeout(() => {
        setEmojiReactions((prevReactions) =>
          prevReactions.filter((r) => r.id !== newReaction.id)
        );
      }, 2000);

      reactionTimeouts.current[newReaction.id] = timeoutId;
    };

    unsubscribeRef.current = listenToEmojiReactions(pin, interactionId, handleReaction);

    return () => {
      if (typeof unsubscribeRef.current === 'function') {
        unsubscribeRef.current();
      }

      Object.values(reactionTimeouts.current).forEach(clearTimeout);
      reactionTimeouts.current = {};
    };
  }, [pin, interactionId]);

  return (
    <div className={styles.multimediaProjectionContainer}>
      {interaction?.image && <img src={interaction.image} alt="Slide Image" className={styles.multimediaImage} />}
      {interaction?.youtubeLink && renderYouTubeEmbed(interaction.youtubeLink)}
      {interaction?.text && <p className={styles.multimediaText}>{interaction.text}</p>}
      {interaction?.buttonText && interaction?.buttonLink && (
        <a href={interaction.buttonLink} target="_blank" rel="noopener noreferrer" className={styles.multimediaButton}>
          {interaction.buttonText}
        </a>
      )}
     
      <div className={styles.emojiContainer}>
        {emojiReactions.map((reaction) => (
          <span
            key={reaction.id}
            className={styles.emojiBubble}
            style={{ left: reaction.position.left, top: reaction.position.top }}
          >
            {reaction.emoji}
          </span>
        ))}
      </div>
    </div>
  );
}

export default MultimediaProjection;
