import React from 'react';
import styles from './AnswerRevealed.module.css';

const AnswerRevealed = ({ question, participant, score, totalScore, isCorrect }) => {
  const response = participant?.responses[question.index];
  const hasAnswered = response !== undefined;

  return (
    <div className={styles.answerRevealed}>
      <h2 className={styles.questionTitle}>{question.text}</h2>
      <div className={styles.avatarContainer}>
        <img src={participant.photoURL} alt="Avatar do participante" className={styles.avatar} />
      </div>
      <p className={isCorrect ? styles.correctMessage : styles.incorrectMessage}>
        {hasAnswered ? (isCorrect ? 'VOCÊ ACERTOU! PARABÉNS' : 'VOCÊ ERROU!') : 'VOCÊ NÃO RESPONDEU!'}
      </p>
      {hasAnswered && (
        <div className={styles.scoreContainer}>
          <p className={styles.score}>{score} PTS</p>
        </div>
      )}
      <div className={styles.optionsContainer}>
        {question.options.map((option, index) => (
          <div
            key={index}
            className={`${styles.optionItem} ${index === question.correctOption ? styles.correct : ''}`}
          >
            {option}
          </div>
        ))}
      </div>
      {hasAnswered && !isCorrect && (
        <div className={styles.chosenOption}>
          <p>Você escolheu: {question.options[response.answer]}</p>
        </div>
      )}
      <div className={styles.totalScoreContainer}>
        <p className={styles.totalScore}>Sua pontuação atual é {totalScore}, você é {participant.rank}º Colocado!</p>
      </div>
    </div>
  );
};

export default AnswerRevealed;
