import React from 'react';
import WordCloudComponent from './WordCloudComponent';
import PreviewContainer from '../../components/PreviewContainer';
import styles from './WordcloudPreview.module.css';

const WordcloudPreview = ({ interaction }) => {
  const words = [
    { text: "Animal!", value: 30 },
    { text: "quero biz", value: 20 },
    { text: "d+", value: 25 },
    { text: "sensacional", value: 40 },
    { text: "incrível", value: 60 },
    { text: "Esclarecedor", value: 30 },
    { text: "amei", value: 35 },
    { text: "show", value: 20 },
    { text: "maravilhoso", value: 50 },
    { text: "engajador", value: 40 },
    { text: "🎉", value: 25 },
    { text: "😄", value: 20 },
    { text: "🚀", value: 30 },
    { text: "😎", value: 20 },
    { text: "😍", value: 35 },
    { text: "👏", value: 25 },
  ];

  const options = {
    width: 500, // Defina a largura desejada
    height: 500, // Defina a altura desejada
    colors: ["#E6C65A", "#E65A5A", "#6913C7", "#DA71FF", "#5A7FE6", "#3AAC60"],
    fontFamily: "montserrat",
    fontSizes: [20, 100],
    rotations: 3,
    rotationAngles: [0, -90, 90],
    padding: 10,
    scale: "log",
    transitionDuration: 1000,
  };

  return (
    <PreviewContainer pin="0000">
      <h2>{interaction.title || "Título da Nuvem de Palavras"}</h2>
      <div className={styles.wordcloudContainer}>
        <WordCloudComponent words={words} options={options} />
      </div>
    </PreviewContainer>
  );
};

export default WordcloudPreview;
