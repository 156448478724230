// src/pages/Learn.js

import React from 'react';
import Sidebar from '../components/Sidebar';
import styles from './Learn.module.css';

function Learn() {
  return (
    <div className={styles.learnContainer}>
      <Sidebar />
      <div className={styles.mainContent}>
       
        <main className={styles.learnMain}>
          <h2>Aprender</h2>
          <p>Em breve!</p>
        </main>
     
      </div>
    </div>
  );
}

export default Learn;
