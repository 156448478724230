import React, { useState, useEffect } from 'react';
import { listenToInsightResponses } from './insights';
import styles from './InsightsProjection.module.css';

const InsightsProjection = ({ interaction, pin, interactionId }) => {
  const [responses, setResponses] = useState({});
  const [widths, setWidths] = useState({});
  const [colors, setColors] = useState({});
  const [previousColorIndex, setPreviousColorIndex] = useState(null);
  const colorPalette = ['#E6C65A', '#D764FF', '#3AAC60', '#E65A5A', '#5A7FE6'];

  useEffect(() => {
    const fetchResponses = async () => {
      const unsubscribe = await listenToInsightResponses(pin, interactionId, setResponses);
      return () => unsubscribe();
    };

    fetchResponses();
  }, [pin, interactionId]);

  useEffect(() => {
    setWidths(prevWidths => {
      const newWidths = { ...prevWidths };
      Object.values(responses).forEach(response => {
        if (!newWidths[response.text]) {
          newWidths[response.text] = getRandomWidthClass();
        }
      });
      return newWidths;
    });

    setColors(prevColors => {
      const newColors = { ...prevColors };
      Object.values(responses).forEach(response => {
        if (!newColors[response.text]) {
          newColors[response.text] = getRandomColor();
        }
      });
      return newColors;
    });
  }, [responses]);

  const getRandomWidthClass = () => {
    const widthClasses = [styles.widthSmall, styles.widthMedium, styles.widthLarge];
    return widthClasses[Math.floor(Math.random() * widthClasses.length)];
  };

  const getRandomColor = () => {
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * colorPalette.length);
    } while (newIndex === previousColorIndex);
    setPreviousColorIndex(newIndex);
    return colorPalette[newIndex];
  };

  return (
    <div className={styles.insightsProjectionContainer}>
      <div className={styles.insightsContainer}>
        {Object.values(responses).map((response, index) => (
          <div
            key={index}
            className={`${styles.insightItem} ${widths[response.text]}`}
            style={{ backgroundColor: colors[response.text] }}
          >
            {response.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InsightsProjection;
