import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getPresentationByPin, updateCurrentInteractionIndex, listenToCurrentInteractionIndexChanges } from '../firebaseFunctions';
import '../assets/wordcloud.css';
import styles from './Projection.module.css';
import interactionMapping from '../interactions/interactionMapping';
import logo from '../images/youpin-logo-branco.png';

// icones
import NextIcon from '../images/control bar icons/next-icon.svg';
import PrevIcon from '../images/control bar icons/previous-icon.svg';
import PauseIcon from '../images/control bar icons/pause-icon.svg';
import QRCodeIcon from '../images/control bar icons/qrcode-icon.svg';
import TimerIcon from '../images/control bar icons/timer-icon.svg';
import QuestionsIcon from '../images/control bar icons/q&a-icon.svg';
import SmartphoneIcon from '../images/control bar icons/smartphone-icon.svg';

// Botões especiais
import WordcloudSpecialButtons from '../interactions/wordcloud/WordcloudSpecialButtons';
import MultimediaSpecialButtons from '../interactions/multimedia/MultimediaSpecialButtons';
import PollSpecialButtons from '../interactions/poll/PollSpecialButtons';
import QuizSpecialButtons from '../interactions/quiz/QuizSpecialButtons';

function Projection() {
  const { pin } = useParams();
  const location = useLocation();
  const [presentation, setPresentation] = useState(null);
  const [currentInteractionIndex, setCurrentInteractionIndex] = useState(0);
  const [specialButtons, setSpecialButtons] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchPresentation = async () => {
      try {
        const presentationData = await getPresentationByPin(pin);
        if (isMounted) {
          const sortedInteractions = Object.values(presentationData.interactions).sort((a, b) => a.order - b.order);
          setPresentation({ ...presentationData, interactions: sortedInteractions });

          // Verifica o parâmetro de consulta
          const queryParams = new URLSearchParams(location.search);
          const interactionIndex = queryParams.get('interaction');

          if (interactionIndex !== null && !isNaN(interactionIndex) && interactionIndex >= 0 && interactionIndex < sortedInteractions.length) {
            const index = parseInt(interactionIndex, 10);
            setCurrentInteractionIndex(index);
            updateCurrentInteractionIndex(pin, index); // Atualiza o índice da interação no banco de dados
          } else {
            setCurrentInteractionIndex(presentationData.currentInteractionIndex || 0);
          }
        }
      } catch (error) {
        console.error('Error fetching presentation data: ', error);
      }
    };

    fetchPresentation();

    const unsubscribe = listenToCurrentInteractionIndexChanges(pin, (index) => {
      if (isMounted) {
        setCurrentInteractionIndex(index);
      }
    });

    return () => {
      isMounted = false;
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [pin, location.search]);

  useEffect(() => {
    if (presentation) {
      const currentInteraction = presentation.interactions[currentInteractionIndex];
      if (currentInteraction) {
        switch (currentInteraction.type) {
          case 'wordcloud':
            setSpecialButtons(<WordcloudSpecialButtons />);
            break;
          case 'multimedia':
            setSpecialButtons(<MultimediaSpecialButtons />);
            break;
          case 'poll':
            setSpecialButtons(<PollSpecialButtons />);
            break;
          case 'quiz':
            setSpecialButtons(
              <QuizSpecialButtons
                pin={pin}
                interactionId={currentInteraction.id}
                currentQuestionIndex={currentInteraction.state.currentQuestionIndex}
                quizState={currentInteraction.quizState}
                totalQuestions={currentInteraction.questions.length}
              />
            );
            break;
          default:
            setSpecialButtons(null);
            break;
        }
      }
    }
  }, [currentInteractionIndex, presentation]);

  const handleNextInteraction = () => {
    const nextIndex = currentInteractionIndex + 1;
    if (nextIndex < presentation.interactions.length) {
      setCurrentInteractionIndex(nextIndex);
      updateCurrentInteractionIndex(pin, nextIndex);
    }
  };

  const handlePreviousInteraction = () => {
    const prevIndex = currentInteractionIndex - 1;
    if (prevIndex >= 0) {
      setCurrentInteractionIndex(prevIndex);
      updateCurrentInteractionIndex(pin, prevIndex);
    }
  };

  const handleOpenMobileView = () => {
    const mobileWindow = window.open(
      `/participate/${pin}`,
      '_blank',
      'width=500,height=900'
    );

    if (mobileWindow) {
      mobileWindow.focus();
    }
  };

  if (!presentation) {
    return <div>Loading...</div>;
  }

  const currentInteractionId = presentation.interactions[currentInteractionIndex].id;
  const currentInteraction = presentation.interactions[currentInteractionIndex];
  const InteractionComponent = interactionMapping[currentInteraction.type]?.projection;

  if (!InteractionComponent) {
    return <div>Tipo de interação desconhecido</div>;
  }

  return (
    <div className={styles.projectionContainer}>
      <header className={styles.projectionHeader}>
        <img src={logo} alt="YouPin logo" className={styles.projectionLogo} />
        <div className={styles.headerGroup}>
          <span className={styles.headerText}>app.youpin.com/</span>
          <div className={styles.headerPin}>{pin}</div>
        </div>
        <div className={styles.headerGroup}>
          <span className={styles.headerText}>Participantes</span>
          <div className={styles.headerParticipants}>00</div>
        </div>
      </header>
      <div className={styles.projectionContent}>
        <h2>{currentInteraction.title}</h2>
        <InteractionComponent interaction={currentInteraction} pin={pin} interactionId={currentInteractionId} />
      </div>
      <div className={styles.controlPanel}>
        <button
          className={`${styles.controlButton} ${styles.controlPurple}`}
          onClick={handleNextInteraction}
          disabled={currentInteractionIndex === presentation.interactions.length - 1}
        >
          <img src={NextIcon} alt="Next" />
        </button>
        <button
          className={`${styles.controlButton} ${styles.controlPurple}`}
          onClick={handlePreviousInteraction}
          disabled={currentInteractionIndex === 0}
        >
          <img src={PrevIcon} alt="Previous" />
        </button>
        <button className={`${styles.controlButton} ${styles.controlPurple}`}>
          <img src={PauseIcon} alt="Pause" />
        </button>

        {specialButtons}

        <button className={`${styles.controlButton} ${styles.controlGreen}`}>
          <img src={QRCodeIcon} alt="QR Code" />
        </button>
        <button className={`${styles.controlButton} ${styles.controlGreen}`}>
          <img src={TimerIcon} alt="Timer" />
        </button>
        <button className={`${styles.controlButton} ${styles.controlGreen}`}>
          <img src={QuestionsIcon} alt="Questions" />
        </button>
        <button className={`${styles.controlButton} ${styles.controlGreen}`} onClick={handleOpenMobileView}>
          <img src={SmartphoneIcon} alt="Mobile View" />
        </button>
      </div>
    </div>
  );
}

export default Projection;
