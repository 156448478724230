import { getDatabase, ref, set, push, update, onValue } from 'firebase/database';
import { getPresentationIdByPin } from '../../firebaseFunctions'; // Importação da função
import { database } from '../../firebaseConfig'; // Importação do objeto database

/**
 * Cria uma nova interação de nuvem de palavras.
 * @param {string} userId - ID do usuário.
 * @param {string} presentationId - ID da apresentação.
 * @param {Object} interaction - Objeto contendo os dados da interação.
 */
export const createWordcloudInteraction = async (userId, presentationId, interaction) => {
  const db = getDatabase();
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interaction.id}`);
  await set(interactionRef, interaction);
};

/**
 * Retorna a estrutura correta de uma interação de nuvem de palavras.
 * @param {Object} interaction - Objeto contendo os dados da interação.
 * @returns {Object} - Objeto estruturado da interação.
 */
export const createWordcloudObject = (interaction) => {
  return {
    id: interaction.id,
    type: "wordcloud",
    title: interaction.title || "Nuvem de Palavras",
    responses: {},
    createdAt: Date.now()
  };
};

/**
 * Atualiza uma interação de nuvem de palavras existente.
 * @param {string} userId - ID do usuário.
 * @param {string} presentationId - ID da apresentação.
 * @param {string} interactionId - ID da interação.
 * @param {Object} interaction - Objeto contendo os novos dados da interação.
 */
export const updateWordcloudInteraction = async (userId, presentationId, interactionId, interaction) => {
  const db = getDatabase();
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}`);
  await update(interactionRef, interaction);
};

/**
 * Remove uma interação de nuvem de palavras existente.
 * @param {string} userId - ID do usuário.
 * @param {string} presentationId - ID da apresentação.
 * @param {string} interactionId - ID da interação.
 */
export const removeWordcloudInteraction = async (userId, presentationId, interactionId) => {
  const db = getDatabase();
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}`);
  await set(interactionRef, null);
};

/**
 * Envia uma resposta para uma interação de nuvem de palavras.
 * @param {string} pin - PIN da apresentação.
 * @param {string} interactionId - ID da interação.
 * @param {string} response - Resposta do usuário.
 */
export const submitResponse = async (pin, interactionId, response) => {
  try {
    const { presentationId, userId } = await getPresentationIdByPin(pin);
    if (!presentationId) throw new Error('Presentation not found for the given PIN');

    const responseRef = ref(database, `presentations/${userId}/${presentationId}/interactions/${interactionId}/responses`);
    const newResponseRef = push(responseRef);
    await update(newResponseRef, { word: response });
  } catch (error) {
    console.error('Error submitting response:', error);
  }
};

/**
 * Escuta as respostas de uma interação de nuvem de palavras e chama o callback com os dados atualizados.
 * @param {string} pin - PIN da apresentação.
 * @param {string} interactionId - ID da interação.
 * @param {function} callback - Função de callback para lidar com as respostas atualizadas.
 */
export const listenToResponses = async (pin, interactionId, callback) => {
  const { presentationId, userId } = await getPresentationIdByPin(pin);
  if (!presentationId) throw new Error('Presentation not found for the given PIN');

  const responseRef = ref(database, `presentations/${userId}/${presentationId}/interactions/${interactionId}/responses`);
  return onValue(responseRef, (snapshot) => {
    const data = snapshot.val();
    callback(data || {});
  });
};
