import React, { useState, useEffect, useContext } from 'react';
import { getPresentations, deletePresentation, duplicatePresentation } from '../firebaseFunctions';
import Sidebar from '../components/Sidebar';
import styles from './MyPresentations.module.css';
import addIcon from '../images/add-icon.svg';
import playIcon from '../images/play-icon.svg';
import duplicateIcon from '../images/duplicate-icon.svg';
import editIcon from '../images/edit-icon.svg';
import deleteIcon from '../images/delete-icon.svg';
import { AuthContext } from '../firebaseAuthFunctions';
import { useNavigate } from 'react-router-dom';

function MyPresentations() {
  const [presentations, setPresentations] = useState([]);
  const [filteredPresentations, setFilteredPresentations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('dateDesc');
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        const presentations = await getPresentations(currentUser.uid);
        setPresentations(presentations ? Object.entries(presentations).map(([id, presentation]) => ({ id, ...presentation })) : []);
      }
    };
    fetchData();
  }, [currentUser]);

  useEffect(() => {
    let filtered = presentations.filter(presentation =>
      presentation.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (sortOption === 'titleAsc') {
      filtered.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sortOption === 'titleDesc') {
      filtered.sort((a, b) => b.title.localeCompare(a.title));
    } else if (sortOption === 'dateAsc') {
      filtered.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
    } else if (sortOption === 'dateDesc') {
      filtered.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }
    setFilteredPresentations(filtered);
  }, [presentations, searchTerm, sortOption]);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };

  // conta interações dentro de uma apresentação
  const countInteractions = (interactions) => {
    return interactions ? Object.keys(interactions).length : 0;
  };


  // delete
  const handleDelete = async (presentationId) => {
    if (currentUser) {
      const success = await deletePresentation(currentUser.uid, presentationId);
      if (success) {
        setPresentations(prevPresentations => prevPresentations.filter(presentation => presentation.id !== presentationId));
      }
    }
  };

  // duplicar
  const handleDuplicate = async (presentation) => {
    if (currentUser) {
      const newPresentationId = await duplicatePresentation(currentUser.uid, presentation);
      const newPresentation = { ...presentation, id: newPresentationId, title: `${presentation.title} (Cópia)` };
      setPresentations(prevPresentations => [...prevPresentations, newPresentation]);
    }
  };

  // editar
  const handleEdit = (presentation) => {
    navigate('/account/create', { state: { presentation } });
  };

  return (
    <div className={styles.myPresentationsContainer}>
      <Sidebar />
      <div className={styles.mainContent}>
        <main className={styles.myPresentationsMain}>

          <div className={styles.recentPresentations}>
            <div className={styles.presentationsHeader}>
              <div className={styles.recentPresentationHeader}>
                <h2>Minhas Apresentações</h2>
                <p>Aqui estão as apresentações interativas que você criou 🤩</p>
              </div>
              <button onClick={() => navigate('/account/create')} className={styles.addPresentationButton}>
                <img src={addIcon} alt="Add" /> Nova apresentação
              </button>
            </div>

            <div className={styles.filterSection}>
              <input
                type="text"
                placeholder="Buscar..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchInput}
              />
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                className={styles.sortSelect}
              >
                <option value="titleAsc">Título (A-Z)</option>
                <option value="titleDesc">Título (Z-A)</option>
                <option value="dateAsc">Data (Mais antigo)</option>
                <option value="dateDesc">Data (Mais recente)</option>
              </select>
            </div>

            <div className={styles.presentationCards}>
              {filteredPresentations && filteredPresentations.length > 0 ? (
                filteredPresentations.map((presentation, index) => (
                  <div key={`${presentation.id}-${index}`} className={styles.presentationCard}>
                    <h4>{presentation.title}</h4>
                    <p>{countInteractions(presentation.interactions)} Interações</p>
                    <p>{presentation.description}</p>
                    <div className={styles.presentationActions}>
                      <button onClick={() => window.open(`/projection/${presentation.pin}`, '_blank')} className={styles.playButton}>
                        <img src={playIcon} alt="Play" />
                      </button>
                      <button onClick={() => handleDuplicate(presentation)} className={styles.duplicateButton}>
                        <img src={duplicateIcon} alt="Duplicate" />
                      </button>
                      <button onClick={() => handleEdit(presentation)} className={styles.editButton}>
                        <img src={editIcon} alt="Edit" />
                      </button>
                      <button onClick={() => handleDelete(presentation.id)} className={styles.deleteButton}>
                        <img src={deleteIcon} alt="Delete" />
                      </button>
                    </div>
                    <p className={styles.presentationUpdatedAt}>Última execução: {formatDate(presentation.updatedAt)}</p>
                  </div>
                ))
              ) : (
                <p>Crie sua primeira apresentação! 🚀</p>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default MyPresentations;
