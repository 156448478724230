import React, { useEffect, useMemo, useState, useRef } from 'react';
import { submitResponse, listenToResponses } from './wordcloud';
import WordCloudComponent from './WordCloudComponent'; // Importe o novo componente
import styles from './WordCloudParticipant.module.css'; // Importa o CSS Module

/**
 * Componente de participante para interações de nuvem de palavras.
 * @param {Object} props - Propriedades do componente.
 * @param {Object} props.interaction - Dados da interação.
 * @param {string} props.pin - PIN da apresentação.
 * @param {string} props.interactionId - ID da interação.
 */
function WordCloudParticipant({ interaction, pin, interactionId }) {
  const responseRef = useRef(null);
  const [responses, setResponses] = useState({});

  /**
   * Hook useEffect para buscar e escutar as respostas da interação.
   */
  useEffect(() => {
    const fetchResponses = async () => {
      const unsubscribe = await listenToResponses(pin, interactionId, setResponses);
      return () => unsubscribe();
    };

    fetchResponses();
  }, [pin, interactionId]);

  /**
   * useMemo para calcular as palavras e suas frequências na nuvem de palavras.
   */
  const words = useMemo(() => {
    const wordCounts = {};
    Object.values(responses).forEach(response => {
      const word = response.word.toLowerCase();
      wordCounts[word] = (wordCounts[word] || 0) + 1;
    });

    const wordArray = Object.keys(wordCounts).map(word => ({
      text: word,
      value: wordCounts[word] * 10 // Ajuste o multiplicador conforme necessário para tamanho das palavras
    }));

    return wordArray;
  }, [responses]);

  /**
   * Função para enviar uma resposta.
   * @param {Object} e - Evento do formulário.
   */
  const handleSendResponse = async (e) => {
    e.preventDefault();
    const response = responseRef.current.value.trim();
    if (response === '') return;
    await submitResponse(pin, interactionId, response);
    responseRef.current.value = ''; // Limpa o campo de input após envio
  };

  /**
   * Opções para a exibição da nuvem de palavras.
   */
  const options = {
    width: 500, // Defina a largura desejada
    height: 500, // Defina a altura desejada
    colors: ["#E6C65A", "#E65A5A", "#6913C7", "#DA71FF", "#5A7FE6", "#3AAC60"],
    fontFamily: "montserrat",
    fontSizes: [20, 100],
    rotations: 3,
    rotationAngles: [0, -90, 90],
    padding: 10,
    scale: "log",
    transitionDuration: 1000,
  };

  return (
    <div className={styles.wordcloudParticipantContainer}>
      <WordCloudComponent words={words} options={options} />
      <form className={styles.wordcloudInputContainer} onSubmit={handleSendResponse}>
        <input
          ref={responseRef}
          type="text"
          placeholder="Escreva seu texto"
          className={styles.wordcloudInput}
        />
        <button type="submit" className={styles.wordcloudSubmit}>Enviar</button>
      </form>
    </div>
  );
}

export default WordCloudParticipant;
