import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './Preparing.module.css';

const Preparing = () => {
  const [timeRemaining, setTimeRemaining] = useState(5);
  const [percentage, setPercentage] = useState(100);

  useEffect(() => {
    let timer;
    setTimeRemaining(5); // Reinicia a contagem regressiva
    setPercentage(100);

    timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime > 0) {
          setPercentage((prevTime - 1) / 5 * 100);
          return prevTime - 1;
        } else {
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <h3>Prepare-se! A pergunta começará em breve.</h3>
      <div className={styles.timerContainer}>
        <CircularProgressbar
          value={percentage}
          text={`${timeRemaining}`}
          styles={buildStyles({
            textColor: '#E65A5A',
            pathColor: '#E65A5A',
            trailColor: '#d6d6d6',
          })}
        />
      </div>
    </div>
  );
};

export default Preparing;
