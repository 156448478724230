import React, { useState, useEffect } from 'react';
import PreviewContainer from '../../components/PreviewContainer';
import styles from './PollPreview.module.css';

function PollPreview({ interaction }) {
  const [data, setData] = useState(generateRandomData(interaction.options || [{ text: 'Opção 1', color: '#5A7FE6' }, { text: 'Opção 2', color: '#E65A5A' }]));

  useEffect(() => {
    const interval = setInterval(() => {
      setData(generateRandomData(interaction.options || [{ text: 'Opção 1', color: '#5A7FE6' }, { text: 'Opção 2', color: '#E65A5A' }]));
    }, 5000);
    return () => clearInterval(interval);
  }, [interaction.options]);

  useEffect(() => {
    setData(generateRandomData(interaction.options || [{ text: 'Opção 1', color: '#5A7FE6' }, { text: 'Opção 2', color: '#E65A5A' }]));
  }, [interaction]);

  function generateRandomData(options) {
    return options.map(option => ({
      ...option,
      count: Math.floor(Math.random() * 100)
    }));
  }

  const maxCount = Math.max(...data.map(option => option.count), 1);

  return (
    <PreviewContainer pin="0000">
      <div className={styles.pollPreview}>
        <h2>{interaction?.title || 'O QUE VOCÊ ACHOU DA AULA DE HOJE?'}</h2>
        <div className={styles.chartContainer}>
          <div className={styles.chart}>
            {data.map((option, index) => (
              <div key={index} className={styles.barContainer}>
                <div
                  className={styles.bar}
                  style={{ height: `${(option.count / maxCount) * 100}%`, backgroundColor: option.color }}
                >
                    <span className={styles.countLabel}>{option.count}</span>
                </div>

              </div>
            ))}
          </div>
        </div>
        <div className={styles.optionLabels}>
          {data.map((option, index) => (
            <p key={index} className={styles.optionLabel} style={{ backgroundColor: option.color }}>{option.text || `Opção ${index + 1}`}</p>
          ))}
        </div>
      </div>
    </PreviewContainer>
  );
}

export default PollPreview;
