import React, { useEffect, useState } from 'react';
import styles from '../../pages/Projection.module.css';
import { updateQuizState, listenToQuizState, resetQuiz } from './quiz';
import RefreshIcon from '../../images/control bar icons/refresh-icon.svg';
import TrophyIcon from '../../images/control bar icons/trophy-icon.svg';
import NextIcon from '../../images/control bar icons/next-icon.svg';
import PrevIcon from '../../images/control bar icons/previous-icon.svg';
import PlayIcon from '../../images/play-icon.svg';

const QuizSpecialButtons = ({ pin, interactionId, totalQuestions, initialQuizState }) => {
  const [quizState, setQuizState] = useState(initialQuizState);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  useEffect(() => {
    const unsubscribe = listenToQuizState(pin, interactionId, (newQuizState) => {
      setQuizState(newQuizState.quizState);
      setCurrentQuestionIndex(newQuizState.currentQuestionIndex ?? 0);
    });

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [pin, interactionId]);

  const handleNextStep = () => {
    if (quizState === 'question') {
      updateQuizState(pin, interactionId, { quizState: 'answerRevealed', currentQuestionIndex });
    } else if (quizState === 'answerRevealed') {
      updateQuizState(pin, interactionId, { quizState: 'genericScoreboard', currentQuestionIndex });
    } else if (quizState === 'genericScoreboard') {
      if (currentQuestionIndex < totalQuestions - 1) {
        const nextQuestionIndex = currentQuestionIndex + 1;
        updateQuizState(pin, interactionId, { quizState: 'preparing', currentQuestionIndex: nextQuestionIndex });
      } else {
        updateQuizState(pin, interactionId, { quizState: 'scoreboardFinal' });
      }
    } else if (quizState === 'scoreboardFinal') {
      // Reinicia o quiz ou realiza outra ação conforme necessário
    }
  };

  const handlePrevStep = () => {
    if (quizState === 'answerRevealed' && currentQuestionIndex > 0) {
      updateQuizState(pin, interactionId, { quizState: 'question', currentQuestionIndex: currentQuestionIndex - 1 });
    } else if (quizState === 'question' && currentQuestionIndex > 0) {
      updateQuizState(pin, interactionId, { quizState: 'preparing', currentQuestionIndex: currentQuestionIndex - 1 });
    } else if (quizState === 'preparing' && currentQuestionIndex > 0) {
      updateQuizState(pin, interactionId, { quizState: 'answerRevealed', currentQuestionIndex: currentQuestionIndex - 1 });
    }
  };

  const handleShowScoreboard = () => {
    updateQuizState(pin, interactionId, { quizState: 'scoreboardFinal' });
  };

  const handleRestartQuiz = () => {
    resetQuiz(pin, interactionId);
  };

  const handleStartQuiz = () => {
    updateQuizState(pin, interactionId, { quizState: 'preparing', currentQuestionIndex: 0 });
  };

  return (
    <div className={styles.buttonContainer}>
      {quizState === 'waitingForParticipants' ? (
        <button className={`${styles.controlButton} ${styles.controlPink}`} onClick={handleStartQuiz}>
          <img src={PlayIcon} className={styles.icon} alt="Iniciar Quiz" />
        </button>
      ) : (
        <>
          <button className={`${styles.controlButton} ${styles.controlPink}`} onClick={handleNextStep} disabled={quizState === 'preparing'}>
            <img src={NextIcon} className={styles.icon} alt="Próxima Etapa" />
          </button>
          <button className={`${styles.controlButton} ${styles.controlPink}`} onClick={handlePrevStep} disabled={quizState === 'preparing'}>
            <img src={PrevIcon} className={styles.icon} alt="Etapa Anterior" />
          </button>
          <button className={`${styles.controlButton} ${styles.controlPink}`} onClick={handleShowScoreboard} disabled={quizState === 'preparing'}>
            <img src={TrophyIcon} className={styles.icon} alt="Mostrar Placar" />
          </button>
          <button className={`${styles.controlButton} ${styles.controlPink}`} onClick={handleRestartQuiz} disabled={quizState === 'preparing'}>
            <img src={RefreshIcon} className={styles.icon} alt="Recomeçar Quiz" />
          </button>
        </>
      )}
    </div>
  );
};

export default QuizSpecialButtons;
