import React, { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import styles from './MultimediaForm.module.css';

function MultimediaForm({ interaction, handleUpdateInteraction }) {
  const [uploading, setUploading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(''); // Guarda a opção selecionada pelo usuário

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    const storage = getStorage();
    const storageRef = ref(storage, `images/${file.name}`);

    await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);
    handleUpdateInteraction('image', imageUrl);
    setUploading(false);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option === 'imageLink' || option === 'upload') {
      handleUpdateInteraction('youtubeLink', ''); // Limpa o campo do URL do YouTube
    } else if (option === 'youtubeLink') {
      handleUpdateInteraction('image', ''); // Limpa o campo da imagem (URL ou upload)
    }
  };

  return (
    <div className={styles.multimediaForm}>
      <label>Título:</label>
      <input
        type="text"
        placeholder="Título da Interação"
        value={interaction.title}
        onChange={(e) => handleUpdateInteraction('title', e.target.value)}
      />
      <label>Escolha uma opção:</label>
      <select onChange={(e) => handleOptionChange(e.target.value)} value={selectedOption}>
        <option value="">Selecione</option>
        <option value="imageLink">URL da Imagem</option>
        <option value="upload">Upload da Imagem</option>
        <option value="youtubeLink">URL do YouTube</option>
      </select>

      {selectedOption === 'imageLink' && (
        <>
          <label>URL da Imagem:</label>
          <input
            type="text"
            placeholder="URL da Imagem"
            value={interaction.image || ''}
            onChange={(e) => handleUpdateInteraction('image', e.target.value)}
          />
        </>
      )}

      {selectedOption === 'upload' && (
        <>
          <label>Upload da Imagem:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
          />
          {uploading && <p>Uploading...</p>}
        </>
      )}

      {selectedOption === 'youtubeLink' && (
        <>
          <label>URL do Vídeo do YouTube:</label>
          <input
            type="text"
            placeholder="URL do YouTube"
            value={interaction.youtubeLink || ''}
            onChange={(e) => handleUpdateInteraction('youtubeLink', e.target.value)}
          />
        </>
      )}

      <label>Texto:</label>
      <input
        type="text"
        placeholder="Texto"
        value={interaction.text || ''}
        onChange={(e) => handleUpdateInteraction('text', e.target.value)}
      />
      <label>Texto do Botão:</label>
      <input
        type="text"
        placeholder="Texto do Botão"
        value={interaction.buttonText || ''}
        onChange={(e) => handleUpdateInteraction('buttonText', e.target.value)}
      />
      <label>URL do Botão:</label>
      <input
        type="text"
        placeholder="URL do Botão"
        value={interaction.buttonLink || ''}
        onChange={(e) => handleUpdateInteraction('buttonLink', e.target.value)}
      />
    </div>
  );
}

export default MultimediaForm;
