// src/components/PreviewContainer.js
import React from 'react';
import styles from './PreviewContainer.module.css';
import PreviewHeader from './PreviewHeader';

const PreviewContainer = ({ children, pin }) => {
  return (
    <div className={styles.previewContainer}>
      <PreviewHeader pin={pin} />
      <div className={styles.previewContent}>
        {children}
      </div>
    </div>
  );
};

export default PreviewContainer;
