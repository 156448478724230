import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './HomePage.module.css';
import HeaderGlobal from '../components/HeaderGlobal';
import FooterSite from '../components/FooterSite';

import logo from '../images/youpin-logo.png';
import cartaoIcon from '../images/cartao-de-credito.svg';
import heroImage from '../images/Youpin Mockup.png';
import votacaoImg from '../images/votacao-image.png';
import textoAbertoImg from '../images/texto-aberto-image.png';
import batataQuenteImg from '../images/batata-quente-image.png';
import multiMediaImg from '../images/multimidia-image.png';

import quebraGeloImg from '../images/nuvem-quebragelo.png';
import feedBackImg from '../images/texto-feedback.png';
import avaliaEntendimentoImg from '../images/avalie-quiz.png';
import tomeDecisoesImg from '../images/tome-decisoes-votacao.png';

import crie from '../images/CRIE.png';
import analise from '../images/ANALISE.png';
import interaja from '../images/INTERAJA.png';

import naEducacaoImg from '../images/na-educacao.png';
import noCorporativoImg from '../images/no-corporativo.png';

import enterpriseApoioImg from '../images/enterprise-apoio-img.png';
import enterpriseBgImg from '../images/enterprise-bg-img.png';

import instagramIcon from '../images/instagram-icon.svg';
import facebookIcon from '../images/facebook-icon.svg';
import linkedinIcon from '../images/linkedin-icon.svg';

const HomePage = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(quebraGeloImg);
  const [selectedOption, setSelectedOption] = useState('Quebra-Gelo');
  const [animationClass, setAnimationClass] = useState('');

  const handlePinSubmit = (e) => {
    e.preventDefault();
    const pin = e.target.elements.pin.value;
    navigate(`/participate/${pin}`);
  };

  const handleImageChange = (image, option) => {
    setAnimationClass(styles.slideOut);
    setTimeout(() => {
      setSelectedImage(image);
      setSelectedOption(option);
      setAnimationClass(styles.slideIn);
    }, 300);
  };

  return (
    <div className={styles.homeBG}>
      <div className={styles.homeContainer}>
      <HeaderGlobal />

        <div className={styles.pinSection}>
          <p>Está participando de um evento? Digite seu PIN de participação aqui:</p>
          <form onSubmit={handlePinSubmit} className={styles.pinForm}>
            <input type="text" name="pin" placeholder="Digite o PIN" className={styles.pinInput} />
            <button type="submit" className={styles.pinButton}>Acessar</button>
          </form>
        </div>

        <main className={styles.mainContent}>
          <section className={styles.hero}>
            <div className={styles.heroContent}>
              <h1>
                Transforme suas apresentações em <br />
                <span className={styles.highlightText}>experiências interativas e divertidas</span>
              </h1>
              <p className={styles.description}>Aumente o engajamento e torne suas apresentações inesquecíveis com ferramentas interativas e feedback instantâneo.</p>
              <Link to="/signup" className={styles.ctaButton}>Teste grátis</Link>
              <div className={styles.cardIcon}>
                <img src={cartaoIcon} alt="Cartão de Crédito" />
                <p className={styles.disclaimer}>*Não requer cartão de crédito</p>
              </div>
            </div>
          </section>

          <section className={styles.imageSection}>
            <img src={heroImage} alt="Imagem Hero" className={styles.heroImage} />
          </section>

          <section className={styles.cardsSection}>
            <h2>Funciona mesmo se você não possuir um projetor</h2>
            <p className={styles.cardsDescription}>
              Primeira plataforma projetada para ser mobile friendly. Todas as interações possuem uma visualização de resultados na tela do usuário e permite interações mesmo que o host da apresentação não possa compartilhar sua tela.
            </p>
            <div className={styles.cardsContainer}>
              <div className={styles.card}>
                <img src={votacaoImg} alt="Card 1" className={styles.cardImage} />
              </div>
              <div className={styles.card}>
                <img src={textoAbertoImg} alt="Card 2" className={styles.cardImage} />
              </div>
              <div className={styles.card}>
                <img src={batataQuenteImg} alt="Card 3" className={styles.cardImage} />
              </div>
              <div className={styles.card}>
                <img src={multiMediaImg} alt="Card 4" className={styles.cardImage} />
              </div>
            </div>
          </section>

          <section className={styles.carouselSection}>
            <div className={styles.carouselContent}>
              <h2>Crie uma experiência instantânea</h2>
              <p>Rápido de verdade. No YouPin você cria experiências interativas instantâneas de forma rápida.</p>
              <Link to="#" className={styles.learnMore}>saiba mais</Link>
              <div className={styles.carouselButtons}>
                <button
                  className={`${styles.carouselButton} ${selectedOption === 'Quebra-Gelo' ? styles.selectedButton : ''}`}
                  onClick={() => handleImageChange(quebraGeloImg, 'Quebra-Gelo')}
                >
                  Quebra-Gelo
                </button>
                <button
                  className={`${styles.carouselButton} ${selectedOption === 'Feedback Instantâneo' ? styles.selectedButton : ''}`}
                  onClick={() => handleImageChange(feedBackImg, 'Feedback Instantâneo')}
                >
                  Feedback Instantâneo
                </button>
                <button
                  className={`${styles.carouselButton} ${selectedOption === 'Avalie o entendimento' ? styles.selectedButton : ''}`}
                  onClick={() => handleImageChange(avaliaEntendimentoImg, 'Avalie o entendimento')}
                >
                  Avalie o entendimento
                </button>
                <button
                  className={selectedOption === 'Tome decisões' ? styles.selectedButton : ''}
                  onClick={() => handleImageChange(tomeDecisoesImg, 'Tome decisões')}
                >
                  Tome decisões
                </button>
              </div>
            </div>
            <div className={`${styles.carouselImage} ${animationClass}`}>
              <img src={selectedImage} alt="Carrossel" />
            </div>
          </section>

          {/* MAIS QUE SLIDES */}
          <section className={styles.superSlidesSection}>
            <h2>Mais que slides, SUPER SLIDES</h2>
            <p className={styles.superSlidesDescription}>
              Mantenha seu público engajado, e acompanhe o entendimento do conteúdo EM TEMPO REAL mesmo para longas apresentações como aulas e treinamentos. Quando os participantes sentem que estão colaborando com a construção do conteúdo o tempo voa e todos aprendem mais!
            </p>
            <div className={styles.superSlidesContainer}>
              <div className={styles.superSlideCard}>
                <div className={styles.superSlideImageContainer}>
                  <img src={crie} alt="Crie" className={styles.superSlideImage} />
                </div>
                <h3>Crie</h3>
                <p>
                  Adicione slides de conteúdo ou multimídia intercalando com slides interativos que envolvem o público.
                </p>
              </div>
              <div className={styles.superSlideCard}>
                <div className={styles.superSlideImageContainer}>
                  <img src={interaja} alt="Interaja" className={styles.superSlideImage} />
                </div>
                <h3>Interaja</h3>
                <p>
                  Peça para os participantes entrar em sua apresentação através do PIN e colete as respostas em tempo real!
                </p>
              </div>
              <div className={styles.superSlideCard}>
                <div className={styles.superSlideImageContainer}>
                  <img src={analise} alt="Analise" className={styles.superSlideImage} />
                </div>
                <h3>Análise</h3>
                <p>
                  Use o resultado das interações para aperfeiçoar seu conteúdo, garantindo uma experiência de aprendizado eficiente!
                </p>
              </div>
            </div>
          </section>

          {/* Momentos */}
          <section className={styles.educacaoCorporativoSection}>
            <div className={styles.educacaoCorporativoContainer}>
              <div className={styles.educacaoCorporativoCard}>
                <div className={styles.educacaoCorporativoImageContainer}>
                  <img src={naEducacaoImg} alt="Na educação" className={styles.educacaoCorporativoImage} />
                </div>
                <div className={styles.educacaoCorporativoText}>
                  <h3>Na educação</h3>
                  <p>
                    Mantenha seu público engajado, e acompanhe o entendimento do conteúdo EM TEMPO REAL mesmo para longas apresentações como aulas e treinamentos. Quando os participantes sentem que estão colaborando com a construção do conteúdo o tempo voa e todos aprendem mais!
                  </p>
                  <Link to="#" className={styles.educacaoCorporativoButton}>Saiba mais</Link>
                </div>
              </div>
              <div className={styles.educacaoCorporativoCard}>
                <div className={styles.educacaoCorporativoText}>
                  <h3>No corporativo</h3>
                  <p>
                    Mantenha seu público engajado, e acompanhe o entendimento do conteúdo EM TEMPO REAL mesmo para longas apresentações como aulas e treinamentos. Quando os participantes sentem que estão colaborando com a construção do conteúdo o tempo voa e todos aprendem mais!
                  </p>
                  <Link to="#" className={styles.educacaoCorporativoButton}>Saiba mais</Link>
                </div>
                <div className={styles.educacaoCorporativoImageContainer}>
                  <img src={noCorporativoImg} alt="No corporativo" className={styles.educacaoCorporativoImage} />
                </div>
              </div>
            </div>
          </section>

          {/* Seção Enterprise */}
          <section className={styles.enterpriseSection}>
            <div className={styles.enterpriseContainer}>
              <div className={styles.enterpriseBgImageContainer}>
                <img src={enterpriseBgImg} alt="Enterprise Background" className={styles.enterpriseBgImage} />
              </div>
              <div className={styles.enterpriseText}>
                <h3>Enterprise</h3>
                <p>Planos especiais para equipes que desejam se destacar com inovação de verdade!</p>
                <Link to="#" className={styles.enterpriseButton}>Saiba mais sobre o Enterprise</Link>
              </div>
              <div className={styles.enterpriseImageContainer}>
                <img src={enterpriseApoioImg} alt="Enterprise Apoio" className={styles.enterpriseApoioImage} />
              </div>
            </div>
          </section>

          {/* impressiona nas suas apresentações */}
          <section className={styles.impressioneSection}>
            <h2>Impressione com suas apresentações interativas!</h2>
            <p className={styles.impressioneDescription}>
              Primeira plataforma projetada para se mobile friendly. Todas as interações possui uma visualização de resultados na tela do usuário e permite interações mesmo que o host da apresentação não possa compartilhar sua tela.
            </p>
            <div className={styles.impressioneButtonContainer}>
              <Link to="/signup" className={styles.impressioneButton}>Criar apresentação interativa!</Link>
              <p className={styles.noCreditCard}><img src={cartaoIcon} alt="Cartão de Crédito" />Não requer cartão de crédito</p>
            </div>
          </section>

        </main>

        <FooterSite />

      </div>
    </div>
  );
};

export default HomePage;
