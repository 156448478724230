import { getDatabase, ref, set, push, update, increment, onValue } from 'firebase/database';
import { getPresentationIdByPin } from '../../firebaseFunctions';
import { database } from '../../firebaseConfig'; // Importação do objeto database

/**
 * Cria uma nova interação multimídia.
 * @param {string} userId - ID do usuário.
 * @param {string} presentationId - ID da apresentação.
 * @param {Object} interaction - Objeto contendo os dados da interação.
 */
export const createMultimediaInteraction = async (userId, presentationId, interaction) => {
  try {
    const db = getDatabase();
    const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interaction.id}`);

    const interactionData = createMultimediaObject(interaction);

    console.log('Salvando interação com dados:', JSON.stringify(interactionData, null, 2));

    await set(interactionRef, interactionData);
    console.log('Interação salva com sucesso');
  } catch (error) {
    console.error('Erro ao salvar interação:', error);
  }
};

/**
 * Retorna a estrutura correta de uma interação multimídia.
 * @param {Object} interaction - Objeto contendo os dados da interação.
 * @returns {Object} - Objeto estruturado da interação.
 */
export const createMultimediaObject = (interaction) => {
  return {
    id: interaction.id,
    type: "multimedia",
    title: interaction.title || "Título do Slide",
    reactions: { initial: 0 },
    image: interaction.image || "",
    text: interaction.text || "",
    buttonText: interaction.buttonText || "",
    buttonLink: interaction.buttonLink || "",
    youtubeLink: interaction.youtubeLink || "",  // Novo campo adicionado
    createdAt: interaction.createdAt || Date.now()
  };
};


/**
 * Atualiza uma interação multimídia existente.
 * @param {string} userId - ID do usuário.
 * @param {string} presentationId - ID da apresentação.
 * @param {string} interactionId - ID da interação.
 * @param {Object} interaction - Objeto contendo os novos dados da interação.
 */
export const updateMultimediaInteraction = async (userId, presentationId, interactionId, interaction) => {
  try {
    const db = getDatabase();
    const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}`);

    const interactionData = createMultimediaObject(interaction);

    console.log('Atualizando interação com dados:', JSON.stringify(interactionData, null, 2));

    await set(interactionRef, interactionData);
    console.log('Interação atualizada com sucesso');
  } catch (error) {
    console.error('Erro ao atualizar interação:', error);
  }
};

/**
 * Remove uma interação multimídia existente.
 * @param {string} userId - ID do usuário.
 * @param {string} presentationId - ID da apresentação.
 * @param {string} interactionId - ID da interação.
 */
export const removeMultimediaInteraction = async (userId, presentationId, interactionId) => {
  const db = getDatabase();
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}`);
  await set(interactionRef, null);
};

/**
 * Envia uma reação de emoji para uma interação multimídia.
 * @param {string} pin - PIN da apresentação.
 * @param {string} interactionId - ID da interação.
 * @param {string} emoji - Emoji enviado pelo usuário.
 */
export const sendEmojiReaction = async (pin, interactionId, emoji) => {
  try {
    const { presentationId, userId } = await getPresentationIdByPin(pin);
    if (!presentationId) throw new Error('Presentation not found for the given PIN');

    console.log('Sending emoji reaction:', {
      presentationId,
      userId,
      interactionId,
      emoji
    });

    const emojiRef = ref(getDatabase(), `presentations/${userId}/${presentationId}/interactions/${interactionId}/reactions/${emoji}`);
    await update(emojiRef, { count: increment(1) });
  } catch (error) {
    console.error('Error sending emoji reaction:', error);
  }
};

/**
 * Escuta as reações de emojis para uma interação multimídia e chama o callback com os dados atualizados.
 * @param {string} pin - PIN da apresentação.
 * @param {string} interactionId - ID da interação.
 * @param {function} callback - Função de callback para lidar com as reações atualizadas.
 */
export const listenToEmojiReactions = async (pin, interactionId, callback) => {
  try {
    const { presentationId, userId } = await getPresentationIdByPin(pin);
    if (!presentationId) throw new Error('Presentation not found for the given PIN');

    console.log('Listening to emoji reactions:', {
      presentationId,
      userId,
      interactionId
    });

    const reactionsRef = ref(getDatabase(), `presentations/${userId}/${presentationId}/interactions/${interactionId}/reactions`);
    let previousReactions = {};
    let initialLoad = true;

    return onValue(reactionsRef, (snapshot) => {
      const reactions = snapshot.val();
      if (reactions) {
        const newReactions = {};
        Object.keys(reactions).forEach((emoji) => {
          const reaction = reactions[emoji];
          const previousCount = previousReactions[emoji]?.count || 0;

          if (reaction.count > previousCount) {
            if (!initialLoad) {
              for (let i = previousCount; i < reaction.count; i++) {
                callback({ emoji, count: reaction.count });
              }
            }
            newReactions[emoji] = reaction;
          } else {
            newReactions[emoji] = previousReactions[emoji];
          }
        });
        previousReactions = newReactions;
        initialLoad = false;
      }
    });
  } catch (error) {
    console.error('Error listening to emoji reactions:', error);
  }
};


