import React, { useEffect, useState, useRef } from 'react';
import { submitInsightResponse, listenToInsightResponses } from './insights';
import styles from './InsightsParticipant.module.css';

function InsightsParticipant({ interaction, pin, interactionId }) {
  const responseRef = useRef(null);
  const [responses, setResponses] = useState({});
  const [widths, setWidths] = useState({});
  const [colors, setColors] = useState({});
  const [previousColorIndex, setPreviousColorIndex] = useState(null);
  const colorPalette = ['#E6C65A', '#D764FF', '#3AAC60', '#E65A5A', '#5A7FE6'];

  useEffect(() => {
    const fetchResponses = async () => {
      const unsubscribe = await listenToInsightResponses(pin, interactionId, setResponses);
      return () => unsubscribe();
    };

    fetchResponses();
  }, [pin, interactionId]);

  useEffect(() => {
    setWidths(prevWidths => {
      const newWidths = { ...prevWidths };
      Object.values(responses).forEach(response => {
        if (!newWidths[response.text]) {
          newWidths[response.text] = getRandomWidthClass();
        }
      });
      return newWidths;
    });

    setColors(prevColors => {
      const newColors = { ...prevColors };
      Object.values(responses).forEach(response => {
        if (!newColors[response.text]) {
          newColors[response.text] = getRandomColor();
        }
      });
      return newColors;
    });
  }, [responses]);

  const getRandomWidthClass = () => {
    const widthClasses = [styles.widthSmall, styles.widthMedium, styles.widthLarge];
    return widthClasses[Math.floor(Math.random() * widthClasses.length)];
  };

  const getRandomColor = () => {
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * colorPalette.length);
    } while (newIndex === previousColorIndex);
    setPreviousColorIndex(newIndex);
    return colorPalette[newIndex];
  };

  const handleSendResponse = async (e) => {
    e.preventDefault();
    const response = responseRef.current.value.trim();
    if (response === '') return;
    await submitInsightResponse(pin, interactionId, response);
    responseRef.current.value = ''; // Limpa o campo de input após envio
  };

  return (
    <div className={styles.insightsParticipantContainer}>
      <div className={styles.responsesContainer}>
        {Object.values(responses).map((response, index) => (
          <div
            key={index}
            className={`${styles.insightItem} ${widths[response.text]}`}
            style={{ backgroundColor: colors[response.text] }}
          >
            {response.text}
          </div>
        ))}
      </div>
      <form className={styles.insightInputContainer} onSubmit={handleSendResponse}>
        <input
          ref={responseRef}
          type="text"
          placeholder="Escreva seu insight"
          className={styles.insightInput}
        />
        <button type="submit" className={styles.insightSubmit}>Enviar</button>
      </form>
    </div>
  );
}

export default InsightsParticipant;
