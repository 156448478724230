import { getDatabase, ref, set, update, get, onValue, push } from 'firebase/database';
import { getPresentationIdByPin } from '../../firebaseFunctions';
import { database } from '../../firebaseConfig';

// Função para criar uma nova interação de quiz
export const createQuizInteraction = async (userId, presentationId, interaction) => {
  const db = getDatabase();
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interaction.id}`);
  await set(interactionRef, {
    ...interaction,
    state: {
      currentQuestionIndex: 0,
      quizState: 'waitingForParticipants',
      timeRemaining: 5
    }
  });
};

// Função para criar um objeto de interação de quiz
export const createQuizObject = (interaction) => {
  return {
    id: interaction.id,
    type: "quiz",
    title: interaction.title || "Título do Quiz",
    questions: interaction.questions || [],
    createdAt: Date.now(),
    state: {
      currentQuestionIndex: 0,
      quizState: 'waitingForParticipants',
      timeRemaining: 5
    }
  };
};

// Função para atualizar uma interação de quiz existente
export const updateQuizInteraction = async (userId, presentationId, interactionId, interaction) => {
  const db = getDatabase();
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}`);
  await update(interactionRef, interaction);
};

// Função para remover uma interação de quiz
export const removeQuizInteraction = async (userId, presentationId, interactionId) => {
  const db = getDatabase();
  const interactionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}`);
  await set(interactionRef, null);
};

// Função para ouvir as respostas do quiz
export const listenToQuizResponses = (pin, interactionId, questionIndex, callback) => {
  const db = getDatabase();
  getPresentationIdByPin(pin).then(({ userId, presentationId }) => {
    const votesRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/questions/${questionIndex}/votes`);
    return onValue(votesRef, (snapshot) => {
      const votes = snapshot.val() || [];
      callback(votes);
    });
  }).catch((error) => {
    console.error("Error listening to votes:", error);
  });
};

// Função para encontrar participante pelo nome
const findParticipantByName = async (pin, interactionId, participantName) => {
  const { presentationId, userId } = await getPresentationIdByPin(pin);
  if (!presentationId) throw new Error('Presentation not found for the given PIN');

  const db = getDatabase();
  const participantsRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/participants`);
  const participantsSnapshot = await get(participantsRef);
  const participants = participantsSnapshot.val() || {};

  // Encontrar a chave do participante pelo nome
  const participantKey = Object.keys(participants).find(key => participants[key].name === participantName);
  if (!participantKey) throw new Error('Participant not found');

  const participantRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/participants/${participantKey}`);
  return { participantKey, presentationId, userId, participantRef, participantData: participants[participantKey] };
};

// Função para enviar a resposta do quiz
// Função para enviar a resposta do quiz
export const submitQuizResponse = async (pin, interactionId, questionIndex, answerIndex, participantName, questionScore) => {
  try {
    console.log('submitQuizResponse called', { pin, interactionId, questionIndex, answerIndex, participantName, questionScore });
    const { participantKey, presentationId, userId, participantRef } = await findParticipantByName(pin, interactionId, participantName);

    const db = getDatabase();
    const questionRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/questions/${questionIndex}`);
    const questionSnapshot = await get(questionRef);
    const question = questionSnapshot.val();

    const updates = {};
    updates[`responses/${questionIndex}`] = {
      answer: answerIndex,
      questionScore: questionScore,
    };

    // Incrementa os votos para a resposta selecionada
    const currentVotes = question.votes ? question.votes[answerIndex] || 0 : 0;
    const updatedVotes = question.votes || [];
    updatedVotes[answerIndex] = currentVotes + 1;
    updates[`votes`] = updatedVotes;

    const participantsRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/participants`);
    const participantsSnapshot = await get(participantsRef);
    const participants = participantsSnapshot.val() || {};

    updates[`totalScore`] = (participants[participantKey].totalScore || 0) + questionScore;

    await update(participantRef, updates);
    await update(questionRef, { votes: updatedVotes });

    const updatedParticipantSnapshot = await get(participantRef);
    const updatedParticipant = updatedParticipantSnapshot.val();
    
    console.log('Updated participant in database', updatedParticipant);
    return updatedParticipant;
  } catch (error) {
    console.error("Error submitting quiz response:", error);
    // Adicionar lógica de tratamento de erro
  }
};



// Função para ouvir o estado do quiz
export const listenToQuizState = (pin, interactionId, callback) => {
  const db = getDatabase();
  getPresentationIdByPin(pin).then(({ userId, presentationId }) => {
    const quizStateRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/state`);
    return onValue(quizStateRef, (snapshot) => {
      const quizState = snapshot.val();
      callback(quizState);
    });
  }).catch((error) => {
    console.error("Error listening to quiz state:", error);
  });
};

// Função para atualizar o estado do quiz
export const updateQuizState = async (pin, interactionId, newState) => {
  const { presentationId, userId } = await getPresentationIdByPin(pin);
  if (!presentationId) throw new Error('Presentation not found for the given PIN');

  const quizStateRef = ref(database, `presentations/${userId}/${presentationId}/interactions/${interactionId}/state`);
  await update(quizStateRef, newState);
};

// Função para enviar as informações do participante
export const submitParticipantInfo = async (pin, interactionId, participant) => {
  const { presentationId, userId } = await getPresentationIdByPin(pin);
  if (!presentationId) throw new Error('Presentation not found for the given PIN');

  const participantsRef = ref(database, `presentations/${userId}/${presentationId}/interactions/${interactionId}/participants`);
  await push(participantsRef, participant);
};

// Função para ouvir os participantes
export const listenToParticipants = (pin, interactionId, callback) => {
  const db = getDatabase();
  getPresentationIdByPin(pin).then(({ userId, presentationId }) => {
    const participantsRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/participants`);
    return onValue(participantsRef, (snapshot) => {
      const participantsData = snapshot.val();
      const participants = participantsData ? Object.values(participantsData) : [];
      callback(participants);
    });
  }).catch((error) => {
    console.error("Error listening to participants:", error);
  });
};

// Função para resetar o quiz
export const resetQuiz = async (pin, interactionId) => {
  const { presentationId, userId } = await getPresentationIdByPin(pin);
  if (!presentationId) throw new Error('Presentation not found for the given PIN');

  const db = getDatabase();

  // Reseta o estado do quiz
  const quizStateRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/state`);
  await set(quizStateRef, {
    currentQuestionIndex: 0,
    quizState: 'waitingForParticipants',
    timeRemaining: 5
  });

  // Remove todas as respostas e zera os votos
  const answersRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/questions`);
  const questionsSnapshot = await get(answersRef);
  const questions = questionsSnapshot.val() || {};
  for (let questionIndex in questions) {
    if (questions.hasOwnProperty(questionIndex)) {
      await set(ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/questions/${questionIndex}/answers`), null);
      
      // Zera os votos
      const votesRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/questions/${questionIndex}/votes`);
      await set(votesRef, questions[questionIndex].options.map(() => 0));
    }
  }

  // Remove todos os participantes
  const participantsRef = ref(db, `presentations/${userId}/${presentationId}/interactions/${interactionId}/participants`);
  await set(participantsRef, null);
};



export const getUpdatedParticipant = async (pin, interactionId, participantName) => {
  try {
    const { participantKey, presentationId, userId, participantRef } = await findParticipantByName(pin, interactionId, participantName);
    const updatedParticipantSnapshot = await get(participantRef);
    return updatedParticipantSnapshot.val();
  } catch (error) {
    console.error("Error getting updated participant:", error);
  }
};
