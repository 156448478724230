// src/pages/Participate.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getPresentationByPin, listenToCurrentInteractionIndexChanges } from '../firebaseFunctions';
import '../assets/wordcloud.css';
import styles from './Participate.module.css'; // Importa o CSS Module
import interactionMapping from '../interactions/interactionMapping';
import logo from '../images/youpin-logo.png';

function Participate() {
  const { pin } = useParams();
  const [presentation, setPresentation] = useState(null);
  const [currentInteractionIndex, setCurrentInteractionIndex] = useState(0);

  useEffect(() => {
    const fetchPresentation = async () => {
      try {
        const presentationData = await getPresentationByPin(pin);
        const sortedInteractions = Object.values(presentationData.interactions).sort((a, b) => a.order - b.order);
        setPresentation({ ...presentationData, interactions: sortedInteractions });
        setCurrentInteractionIndex(presentationData.currentInteractionIndex || 0);
      } catch (error) {
        console.error('Error fetching presentation data: ', error);
      }
    };

    fetchPresentation();

    const unsubscribe = listenToCurrentInteractionIndexChanges(pin, (index) => {
      setCurrentInteractionIndex(index);
    });

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [pin]);

  if (!presentation) {
    return <div>Loading...</div>;
  }

  const currentInteraction = presentation.interactions[currentInteractionIndex];
  const InteractionComponent = interactionMapping[currentInteraction.type]?.participant;

  if (!InteractionComponent) {
    return <div>Tipo de interação desconhecido</div>;
  }

  return (
    <div className={styles.participateContainer}>
       <h2 className={styles.pinArea}>Pin do evento: {pin}</h2>
      <header className={styles.participateHeader}>
        <button className={styles.headerButton}>Levantar a mão</button>
        <img src={logo} alt="YouPin logo" className={styles.headerLogo} />
        <button className={styles.headerButton}>Dúvidas</button>
      </header>
      <div className={styles.participateContent}>
        <h2>{currentInteraction.title}</h2>
        <InteractionComponent interaction={currentInteraction} pin={pin} interactionId={currentInteraction.id} />
      </div>
    </div>
  );
}

export default Participate;
