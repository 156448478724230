// src/components/PreviewHeader.js
import React from 'react';
import styles from './PreviewHeader.module.css';
import logo from '../images/youpin-logo-branco.png';

const PreviewHeader = ({ pin }) => {
  return (
    <header className={styles.projectionHeader}>
      <img src={logo} alt="YouPin logo" className={styles.projectionLogo} />
      <div className={styles.headerGroup}>
        <span className={styles.headerText}>app.youpin.com/</span>
        <div className={styles.headerPin}>{pin}</div>
      </div>
      <div className={styles.headerGroup}>
        <span className={styles.headerText}>Participantes</span>
        <div className={styles.headerParticipants}>00</div>
      </div>
    </header>
  );
};

export default PreviewHeader;
