import React from 'react';
import styles from './Scoreboard.module.css';

const Scoreboard = ({ participants }) => {
  // Ordenar os participantes por pontuação
  const sortedParticipants = participants.sort((a, b) => b.totalScore - a.totalScore);

  return (
    <div className={styles.scoreboard}>
      <h2>PLACAR PARCIAL</h2>
      <ul className={styles.participantList}>
        {sortedParticipants.map((participant, index) => (
          <li
            key={participant.name}
            className={`${styles.participantItem} ${
              index === 0
                ? styles.gold
                : index === 1
                ? styles.silver
                : index === 2
                ? styles.bronze
                : styles.purple
            }`}
          >
            <img src={participant.photoURL} alt={participant.name} className={styles.avatar} />
            <span className={styles.name}>{participant.name}</span>
            <span className={styles.score}>{participant.totalScore} pts</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Scoreboard;
