import React, { useState, useEffect } from 'react';
import styles from './PollForm.module.css';
import { ReactComponent as DeleteIcon } from '../../images/delete-icon.svg';

function PollForm({ interaction, handleUpdateInteraction }) {
  const [title, setTitle] = useState(interaction.title || '');
  const [options, setOptions] = useState(interaction.options || [{ text: 'Opção 1', color: '#5A7FE6', correct: false }, { text: 'Opção 2', color: '#E65A5A', correct: false }]);
  const [maxSelections, setMaxSelections] = useState(interaction.maxSelections || 1);

  useEffect(() => {
    handleUpdateInteraction('title', title);
  }, [title]);

  useEffect(() => {
    handleUpdateInteraction('options', options);
  }, [options]);

  useEffect(() => {
    handleUpdateInteraction('maxSelections', maxSelections);
  }, [maxSelections]);

  const handleOptionChange = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    const newOptions = [...options, { text: `Opção ${options.length + 1}`, color: getNextColor(), correct: false }];
    setOptions(newOptions);
  };

  const handleRemoveOption = (index) => {
    if (options.length > 2) {
      const newOptions = options.filter((_, i) => i !== index);
      setOptions(newOptions);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleMaxSelectionsChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setMaxSelections(value);
  };

  const getNextColor = () => {
    const colors = ['#E6C65A', '#D764FF', '#3AAC60', '#E65A5A', '#5A7FE6', '#FF1493'];
    const usedColors = options.map(option => option.color);
    return colors.find(color => !usedColors.includes(color)) || '#5A7FE6';
  };

  return (
    <div className={styles.pollForm}>
      <label>Título:</label>
      <input
        type="text"
        placeholder="Título da Pesquisa"
        value={title}
        onChange={handleTitleChange}
      />
      <label>Quantidade de respostas por participante:</label>
      <input
        type="number"
        value={maxSelections}
        min="1"
        max={options.length}
        onChange={handleMaxSelectionsChange}
      />
      <label>Opções:</label>
      {options.map((option, index) => (
        <div key={index} className={styles.optionItem}>
          <input
            type="color"
            value={option.color}
            onChange={(e) => handleOptionChange(index, 'color', e.target.value)}
            className={styles.colorPicker}
            style={{ backgroundColor: option.color }}
          />
          <div className={styles.optionContent}>
            <input
              type="checkbox"
              checked={option.correct}
              onChange={(e) => handleOptionChange(index, 'correct', e.target.checked)}
              className={styles.correctCheckbox}
            />
            <input
              type="text"
              placeholder={`Opção ${index + 1}`}
              value={option.text}
              onChange={(e) => handleOptionChange(index, 'text', e.target.value)}
              className={styles.optionText}
            />
          </div>
          <button type="button" onClick={() => handleRemoveOption(index)} className={styles.deleteButton} disabled={options.length <= 2}>
            <DeleteIcon className={styles.deleteIcon} />
          </button>
        </div>
      ))}
      <button type="button" onClick={handleAddOption} className={styles.addOptionButton}>Adicionar opção</button>
    </div>
  );
}

export default PollForm;
