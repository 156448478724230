import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import styles from './Sidebar.module.css';
import { ReactComponent as HomeIcon } from '../images/home-icon.svg';
import { ReactComponent as PresentationsIcon } from '../images/presentations-icon.svg';
import { ReactComponent as LearnIcon } from '../images/learn-icon.svg';
import { ReactComponent as AiAssistantIcon } from '../images/ai-assistant-icon.svg';
import { ReactComponent as AwardsIcon } from '../images/awards-icon.svg';
import { ReactComponent as PlansIcon } from '../images/plans-icon.svg';
import logo from '../images/youpin-logo.png';
import mobileLogo from '../images/youpin-logo-mobile-header.svg';
import menuIcon from '../images/menu-icon.svg';
import closeIcon from '../images/close-icon.svg';
import { AuthContext } from '../firebaseAuthFunctions';
import { getDatabase, ref, get, child } from "firebase/database";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [plan, setPlan] = useState('');

  useEffect(() => {
    const fetchUserPlan = async () => {
      if (currentUser) {
        const dbRef = ref(getDatabase());
        try {
          const snapshot = await get(child(dbRef, `users/${currentUser.uid}`));
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setPlan(userData.plan || 'Free');
          }
        } catch (error) {
          console.error('Error fetching user plan:', error);
        }
      }
    };

    fetchUserPlan();
  }, [currentUser]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redireciona para a página de login após o logout
    } catch (error) {
      console.error('Erro ao sair:', error);
    }
  };

  return (
    <div>
      <div className={styles.mobileHeader}>
        <Link to="/account/home">
          <img src={mobileLogo} alt="YouPin Logo" className={styles.mobileLogo} />
        </Link>
        <div className={styles.userPlan}>{plan}</div>
        <button className={styles.menuButton} onClick={toggleMenu}>
          <img src={isOpen ? closeIcon : menuIcon} alt="Menu Icon" />
        </button>
      </div>
      <div className={`${styles.sidebar} ${isOpen ? styles.open : ''}`}>
        <Link to="/account/home">
          <img src={logo} alt="YouPin Logo" className={styles.logo} />
        </Link>
        <nav className={styles.nav}>
          <ul>
            <li className={location.pathname === '/account/home' ? styles.active : ''}>
              <Link to="/account/home">
                <HomeIcon className={styles.icon} />
                Início
              </Link>
            </li>
            <li className={location.pathname === '/account/my-presentations' ? styles.active : ''}>
              <Link to="/account/my-presentations">
                <PresentationsIcon className={styles.icon} />
                Minhas Apresentações
              </Link>
            </li>
            <li className={location.pathname === '/account/learn' ? styles.active : ''}>
              <Link to="/account/learn">
                <LearnIcon className={styles.icon} />
                Aprender
                <span className={styles.comingSoon}>Em breve!</span>
              </Link>
            </li>
            <li className={location.pathname === '/account/plans' ? styles.active : ''}>
              <Link to="/account/plans">
                <PlansIcon className={styles.icon} />
                Planos
                <span className={styles.comingSoon}>Em breve!</span>
              </Link>
            </li>
            <li className={location.pathname === '/account/awards' ? styles.active : ''}>
              <Link to="/account/awards">
                <AwardsIcon className={styles.icon} />
                Awards
                <span className={styles.comingSoon}>Em breve!</span>
              </Link>
            </li>
            <li className={location.pathname === '/account/ai-assistant' ? styles.active : ''}>
              <Link to="/account/ai-assistant">
                <AiAssistantIcon className={styles.icon} />
                Assistente I.A
                <span className={styles.comingSoon}>Em breve!</span>
              </Link>
            </li>
          </ul>
        </nav>
        <button className={styles.logoutButton} onClick={handleSignOut}>
          Sair
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
