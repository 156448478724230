import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, update } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { AuthContext } from '../firebaseAuthFunctions';
import { getAuth, updatePassword } from 'firebase/auth';
import Sidebar from '../components/Sidebar';
import styles from './Profile.module.css';
import { ReactComponent as BackIcon } from '../images/back-arrow-icon.svg';

function Profile() {
  const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    cpf: '',
    phone: '',
    birthDate: '',
    photoURL: ''
  });
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState('profile');
  const [showOptions, setShowOptions] = useState(window.innerWidth > 768);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      fetchUserData();
      checkIfGoogleUser();
    }
  }, [currentUser]);

  const fetchUserData = async () => {
    const db = getDatabase();
    const userRef = ref(db, `users/${currentUser.uid}`);
    const snapshot = await get(userRef);
    setUserData(snapshot.val() || {});
    setLoading(false);
  };

  const checkIfGoogleUser = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const providerData = user.providerData;
    const googleProvider = providerData.find(provider => provider.providerId === 'google.com');
    setIsGoogleUser(!!googleProvider);
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError('As senhas não coincidem');
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      await updatePassword(user, newPassword);
      setSuccess('Senha atualizada com sucesso');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setError('Erro ao atualizar a senha: ' + error.message);
    }
  };

  const handleUpdateProfile = async () => {
    setError('');
    setSuccess('');
    try {
      const db = getDatabase();
      const userRef = ref(db, `users/${currentUser.uid}`);
      await update(userRef, userData);
      setSuccess('Dados atualizados com sucesso!');
    } catch (error) {
      setError('Erro ao atualizar os dados: ' + error.message);
    }
  };

  const handlePlanUpgrade = async (newPlan) => {
    const db = getDatabase();
    const userRef = ref(db, `users/${currentUser.uid}`);
    await update(userRef, { planType: newPlan, eventLimit: getEventLimit(newPlan) });
    setSuccess('Plano atualizado com sucesso!');
    fetchUserData();
  };

  const getEventLimit = (planType) => {
    switch (planType) {
      case 'Basic':
        return 10;
      case 'Pro':
        return 100;
      default:
        return 1;
    }
  };

  const handleCPFChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    let formattedValue = value;
    if (value.length > 3) {
      formattedValue = value.replace(/(\d{3})(\d)/, '$1.$2');
    }
    if (value.length > 6) {
      formattedValue = value.replace(/(\d{3})(\d{3})(\d)/, '$1.$2.$3');
    }
    if (value.length > 9) {
      formattedValue = value.replace(/(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3-$4');
    }
    setUserData({ ...userData, cpf: formattedValue });
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    let formattedValue = value;
    if (value.length > 2) {
      formattedValue = value.replace(/(\d{2})(\d)/, '($1) $2');
    }
    if (value.length > 7) {
      formattedValue = value.replace(/(\d{2})(\d{5})(\d)/, '($1) $2-$3');
    }
    setUserData({ ...userData, phone: formattedValue });
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storage = getStorage();
      const storageReference = storageRef(storage, `profilePhotos/${currentUser.uid}`);
      await uploadBytes(storageReference, file);
      const photoURL = await getDownloadURL(storageReference);
      setUserData({ ...userData, photoURL });
      setSuccess('Foto de perfil atualizada com sucesso!');
    }
  };

  const handleSectionClick = (sectionName) => {
    setSection(sectionName);
    setShowOptions(false);
  };

  const handleBackClick = () => {
    setShowOptions(true);
  };



  if (loading) return <div className={styles.loading}>Carregando...</div>;

  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.mainContent}>
        <header className={styles.topSection}>
          <h2 className={styles.welcome}>Informações da conta</h2>
          <div className={styles.userActions}>
            <button className={styles.upgradeButton}>Upgrade</button>
            <div className={styles.userAvatar}>
              {userData.photoURL ? (
                <img src={userData.photoURL} alt="Avatar" className={styles.avatarImage} />
              ) : (
                userData.firstName && userData.lastName ? `${userData.firstName[0]}${userData.lastName[0]}` : 'TF'
              )}
            </div>
          </div>
        </header>
        <main className={styles.accountHomeMain}>
          <div className={`${styles.profileOptions} ${showOptions ? styles.show : styles.hide}`}>
            <ul>
              <li className={section === 'profile' ? styles.active : ''} onClick={() => handleSectionClick('profile')}>Meu Perfil</li>
              <li className={section === 'address' ? styles.active : ''} onClick={() => handleSectionClick('address')}>Endereço</li>
              <li className={section === 'security' ? styles.active : ''} onClick={() => handleSectionClick('security')}>Segurança</li>
              <li className={section === 'plans' ? styles.active : ''} onClick={() => handleSectionClick('plans')}>Planos</li>
              <li className={section === 'history' ? styles.active : ''} onClick={() => handleSectionClick('history')}>Histórico</li>
            </ul>
          </div>
          <div className={`${styles.profileContent} ${showOptions ? styles.hide : 'show'}`}>
            

            <button className={styles.backButton} onClick={handleBackClick}> <BackIcon /></button>
            {section === 'profile' && (
              <>
                <h3>Meu Perfil</h3>
                {error && <p className={styles.error}>{error}</p>}
                {success && <p className={styles.success}>{success}</p>}
                <div className={styles.profileBox}>
                  <div className={styles.profilePhoto}>
                    <div className={styles.avatar}>
                      {userData.photoURL ? (
                        <img src={userData.photoURL} alt="Avatar" className={styles.avatarImage} />
                      ) : (
                        userData.firstName && userData.lastName ? `${userData.firstName[0]}${userData.lastName[0]}` : 'TF'
                      )}
                    </div>
                    <input
                      type="file"
                      id="fileInput"
                      onChange={handlePhotoUpload}
                      className={styles.uploadButton}
                    />
                    <label htmlFor="fileInput" className={styles.customUploadButton}>
                      Carregar foto
                    </label>
                  </div>
                  <div className={styles.profileDetails}>
                    <div className={styles.formGroup}>
                      <label>Nome:</label>
                      <input
                        type="text"
                        value={userData.firstName || ''}
                        onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <label>Sobrenome:</label>
                      <input
                        type="text"
                        value={userData.lastName || ''}
                        onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <label>CPF:</label>
                      <input
                        type="text"
                        value={userData.cpf || ''}
                        onChange={handleCPFChange}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <label>Telefone:</label>
                      <input
                        type="text"
                        value={userData.phone || ''}
                        onChange={handlePhoneChange}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <label>Data de Nascimento:</label>
                      <input
                        type="date"
                        value={userData.birthDate || ''}
                        onChange={(e) => setUserData({ ...userData, birthDate: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.profileActions}>
                  <button className={styles.cancelButton} onClick={() => navigate('/account/home')}>Cancelar alterações e sair</button>
                  <button className={styles.saveButton} onClick={handleUpdateProfile}>Salvar alterações</button>
                </div>
              </>
            )}
            {section === 'address' && (
              <>
                <h3>Endereço</h3>
                {/* Conteúdo da seção de endereço */}
              </>
            )}
            {section === 'security' && (
              <>
                <h3>Segurança</h3>
                {isGoogleUser ? (
                  <p className={styles.info}>Você fez login com o Google. Para alterar sua senha, acesse as configurações da sua conta Google.</p>
                ) : (
                  <>
                    {error && <p className={styles.error}>{error}</p>}
                    {success && <p className={styles.success}>{success}</p>}
                    <div className={styles.profileBox}>
                      <div className={styles.profileDetails}>
                        <div className={styles.formGroup}>
                          <label>Nova Senha:</label>
                          <input
                            type="password"
                            placeholder="Digite a nova senha"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className={styles.profileInput}
                          />
                        </div>
                        <div className={styles.formGroup}>
                          <label>Confirme a Nova Senha:</label>
                          <input
                            type="password"
                            placeholder="Confirme a nova senha"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className={styles.profileInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.profileActions}>
                      <button className={styles.cancelButton} onClick={() => navigate('/account/home')}>Cancelar alterações e sair</button>
                      <button className={styles.saveButton} onClick={handleUpdatePassword}>Salvar alterações</button>
                    </div>
                  </>
                )}
              </>
            )}
            {section === 'plans' && (
              <>
                <h3>Planos</h3>
                {/* Conteúdo da seção de planos */}
              </>
            )}
            {section === 'history' && (
              <>
                <h3>Histórico</h3>
                {/* Conteúdo da seção de histórico */}
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );


}

export default Profile;
