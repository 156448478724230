import React from 'react';
import RefreshIcon from '../../images/control bar icons/refresh-icon.svg';
import TrophyIcon from '../../images/control bar icons/trophy-icon.svg';
import styles from '../../pages/Projection.module.css';

const handleSpecialAction1 = () => {
  console.log('Ação Especial 1');
  // Adicionar lógica para ação especial 1
};

const handleSpecialAction2 = () => {
  console.log('Ação Especial 2');
  // Adicionar lógica para ação especial 2
};

function MultimediaSpecialButtons() {
  return (
    <>
      <button className={`${styles.controlButton} ${styles.controlPink}` } onClick={handleSpecialAction1}>
        <img src={RefreshIcon} alt="Ação Especial 1" />
      </button>
      <button className={`${styles.controlButton} ${styles.controlPink}` } onClick={handleSpecialAction2}>
        <img src={TrophyIcon} alt="Ação Especial 2" />
      </button>
    </>
  );
}

export default MultimediaSpecialButtons;
