// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CreatePresentation from './pages/CreatePresentation';
import JoinPresentation from './pages/JoinPresentation';
import Participate from './pages/Participate';
import Projection from './pages/Projection';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import BetaAlert from './components/BetaAlert';
import './App.module.css';
import AccountHome from './pages/AccountHome';
import MyPresentations from './pages/MyPresentations';
import Plans from './pages/Plans';
import Awards from './pages/Awards';
import AiAssistant from './pages/AiAssistant';
import Learn from './pages/Learn';
import Profile from './pages/Profile';
import { AuthProvider } from './firebaseAuthFunctions';

function App() {
  return (
    <AuthProvider>
      <Router>
        {/* <BetaAlert /> */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signin" element={<PublicRoute><Signin /></PublicRoute>} />
          <Route path="/signup" element={<PublicRoute><Signup /></PublicRoute>} />
          <Route path="/account/home" element={<PrivateRoute><AccountHome /></PrivateRoute>} />
          <Route path="/account/my-presentations" element={<PrivateRoute><MyPresentations /></PrivateRoute>} />
          <Route path="/account/create" element={<PrivateRoute><CreatePresentation /></PrivateRoute>} />
          <Route path="/account/plans" element={<PrivateRoute><Plans /></PrivateRoute>} />
          <Route path="/account/awards" element={<PrivateRoute><Awards /></PrivateRoute>} />
          <Route path="/account/learn" element={<PrivateRoute><Learn /></PrivateRoute>} />
          <Route path="/account/ai-assistant" element={<PrivateRoute><AiAssistant /></PrivateRoute>} />
          <Route path="/join" element={<JoinPresentation />} />
          <Route path="/participate/:pin" element={<Participate />} />
          <Route path="/projection/:pin" element={<Projection />} />
          <Route path="/account/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
