import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Signin.module.css';
import { Link } from 'react-router-dom';
import logo from '../images/youpin-logo.png';
import googleIcon from '../images/google-icon.svg';
import { signinUser, signInWithGoogle } from '../userFunctions';

function Signin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signinUser(email, password);
      navigate('/account/home');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignin = async () => {
    try {
      await signInWithGoogle();
      navigate('/account/home');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className={styles.signinPage}>
      <div className={styles.signinFormContainer}>
        <Link to={"/"}>
          <img src={logo} alt="YouPin Logo" className={styles.signinLogo} />
        </Link>
        <h2>Bem-vindo(a) ao YouPin! 😊</h2>
        <p>Entre com seu e-mail e senha</p>
        {error && <p className={styles.errorMessage}>{error}</p>}
        <form onSubmit={handleSignin}>
          <input
            type="email"
            placeholder="Email"
            className={styles.signinInput}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Senha"
            className={styles.signinInput}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className={styles.signinButton}>Entrar</button>
          <p className={styles.signinForgotPassword}>Esqueci minha senha</p>
          <div className={styles.signinDivider}>
            <span>OU</span>
          </div>
          <button type="button" className={styles.signinGoogleButton} onClick={handleGoogleSignin}>
            <img src={googleIcon} alt="Google Icon" />
            Entre com sua conta do Google
          </button>
        </form>

        <div className={styles.divisor}></div>

        <p className={styles.signupPrompt}>Não tem uma conta?</p>
        <button type="button" className={styles.signupButton}>
          <Link to="/signup" className={styles.signupLink}>Crie sua conta grátis!</Link>
        </button>
      </div>
    </div>
  );
}

export default Signin;
